import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs';
import { AudioTrack, AudioTrackComponent } from 'src/app/shared/audio-track/audio-track.component';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';
import { Quiz } from '../../services/quiz';

@Component({
  selector: 'app-onboarding-quiz-step',
  templateUrl: './quiz-step.component.html',
  styleUrls: ['./quiz-step.component.scss']
})
export class QuizStepComponent implements OnInit {
  data: Quiz;
  selectedOptions: string[] = [];

  currentIndex: number;
  stepsCount: number;

  @ViewChildren('audioTrackRef') audioTracks!: QueryList<AudioTrackComponent>;

  constructor(
    private route: ActivatedRoute,
    private flowService: OnboardingFlowService
  ) {}

  ngOnInit(): void {
    this.stepsCount = this.flowService.getQuizLength();

    this.route.paramMap
      .pipe(
        tap(params => {
          this.currentIndex = parseInt(params.get('index') || '0', 10);
          this.data = this.flowService.getQuizData(this.currentIndex);
          this.selectedOptions = [];
    
          if (!this.data) {
            this.flowService.goToStart();
          }
        }),
      )
      .subscribe();
  }

  buttonClicked(): void {
    if (this.data.isRequired && !this.selectedOptions.length) {
      return;
    }

    this.finish([...this.selectedOptions]);
  }

  voteClicked(choice: 'yes' | 'no' | 'miss'): void {
    this.finish([choice]);
  }

  optionClicked(): void {
    if (this.data.isMultiple) {
      return;
    }

    this.finish([...this.selectedOptions]);
  }

  onPlay(currentTrack: AudioTrackComponent, track: AudioTrack): void {
    this.audioTracks.forEach(trackComponent => {
      if (trackComponent !== currentTrack) {
        trackComponent.pause();
      }
    });

    this.flowService.rememberLastPlayedTrack(track);
  }

  private finish(options: string[]): void {    
    this.flowService.handleFinishedStep(this.currentIndex, options);
  }
}
