import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Rive } from '@rive-app/canvas';
import { RiveAnimationConfig } from 'src/app/onboarding/services/quiz';

export interface ModalData {
  title: string;
  message: string;
  okButtonTitle?: string;
  rive?: RiveAnimationConfig;
  isConfirmation?: boolean;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild('animation') riveAnimation: ElementRef;

  title: string;
  message: string;
  okButtonTitle: string | undefined;
  isConfirmation: boolean | undefined = false;
  rive: RiveAnimationConfig | undefined;

  private riveInstance: Rive | undefined;

  constructor(
    private dialogRef: MatDialogRef<ModalComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) data: ModalData,
    ) {
      this.title = data.title;
      this.message = data.message;
      this.isConfirmation = data.isConfirmation;
      this.okButtonTitle = data.okButtonTitle;
      this.rive = data.rive;
    }

  ngAfterViewInit(): void {
    if (this.rive) {
      const canvas = this.riveAnimation.nativeElement;

      this.riveInstance = new Rive({
        src: `assets/rive/${this.rive.fileName}.riv`,
        canvas,
        autoplay: true,
        animations: this.rive.animations,
        onLoad: () => this.riveInstance?.resizeToCanvas(),
      });
    }
  }

  ngOnDestroy(): void {
    this.riveInstance?.stop();
    this.riveInstance?.cleanup();
  }

  close(isOk: boolean) {
    this.dialogRef.close(isOk);
  }
}
