import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { AudioTrack } from 'src/app/shared/audio-track/audio-track.component';
import { UserReview } from 'src/app/shared/user-review/user-review.component';
import { audioTracks, highlightText, interests, Quiz, QuizStepKey, quizSteps } from './quiz';

enum Cohort {
  disability,
  education,
  productivity,
  fiction,
}

enum Occupation {
  student,

  officeWorker,
  serviceWorker,

  entrepreneur,

  creative,
  contentCreator,

  stayAtHome,
  retired,
  other,
}

enum ConsumptionTime {
  morning,
  commute,
  workingOut,
  break,
}

enum Gender {
  male,
  female,
  other,
}

interface PaywallTitle {
  cohorts: Cohort[];
  occupationGroup: Occupation[];
  title: string;
}

interface PaywallImage {
  cohort: Cohort;
  consumptionTimes: ConsumptionTime[];
  image: string;
}

type Interest = typeof interests[number];

const interestTrackMap: { [key in Interest]: AudioTrack } = {
  '🏛 History': audioTracks.history,
  '💼 Business Management': audioTracks.business,
  '🏥 Clinical Medicine': audioTracks.medicine,
  '🔬 Physics': audioTracks.physics,
  '💔 Dark Romance': audioTracks.darkRomance,
  '🌍 Environment & Sustainability': audioTracks.environment,
  '📖 Fan Fiction': audioTracks.fanfiction,
  '🛡 Fantasy': audioTracks.fantasy,
  '📈 Finance & Investing': audioTracks.finance,
  '🏋️ Fitness & Health': audioTracks.fitness,
  '📢 Marketing': audioTracks.marketing,
  '🧠 Mental Health': audioTracks.mentalHealth,
  '📰 News & Current Events': audioTracks.news,
  '👪 Parenting & Family': audioTracks.parenting,
  '📈 Personal Growth & Motivation': audioTracks.personalGrowth,
  '📜 Religion & Spirituality': audioTracks.religion,
  '❤️ Romance': audioTracks.romance,
  '🔍 Thriller & Mystery': audioTracks.thrillerMystery,
  '🏦 Wealth Building': audioTracks.wealthBuilding,
  '💆 Well-Being': audioTracks.wellBeing,
  '🔬 Science': audioTracks.science,
};

const paywallTitles: PaywallTitle[] = [
  {
    cohorts: [Cohort.productivity],
    occupationGroup: [Occupation.officeWorker, Occupation.serviceWorker, Occupation.entrepreneur],
    title: `${highlightText('Maximize Your Efficiency:')} Stay Ahead with Peech`,
  },
  {
    cohorts: [Cohort.productivity],
    occupationGroup: [Occupation.contentCreator, Occupation.creative],
    title: `${highlightText('Boost Your Creativity:')} Achieve More with Peech`,
  },
  {
    cohorts: [Cohort.productivity, Cohort.education],
    occupationGroup: [Occupation.student],
    title: `${highlightText('Enhance Your Learning:')} Make Every Moment Count with Peech`,
  },
  {
    cohorts: [Cohort.productivity],
    occupationGroup: [Occupation.retired, Occupation.other, Occupation.stayAtHome],
    title: `${highlightText('Stay Engaged and Active:')} Make the Most of Your Day with Peech`,
  },
  {
    cohorts: [Cohort.education],
    occupationGroup: [Occupation.serviceWorker, Occupation.officeWorker, Occupation.entrepreneur],
    title: `${highlightText('Grow Your Knowledge:')} Learn and Succeed with Peech`,
  },
  {
    cohorts: [Cohort.education],
    occupationGroup: [Occupation.contentCreator, Occupation.creative],
    title: `${highlightText('Master New Skills:')} Learn Anytime, Anywhere with Peech`,
  },
  {
    cohorts: [Cohort.education],
    occupationGroup: [Occupation.retired, Occupation.other, Occupation.stayAtHome],
    title: `${highlightText('Keep Learning:')} Expand Your Horizons with Peech`,
  },
  {
    cohorts: [Cohort.education],
    occupationGroup: [Occupation.student],
    title: `${highlightText('Ace Your Studies:')} Boost Learning and Stay Ahead with Peech`,
  },
  {
    cohorts: [Cohort.fiction],
    occupationGroup: [Occupation.serviceWorker, Occupation.officeWorker, Occupation.entrepreneur, Occupation.student, Occupation.contentCreator, Occupation.creative],
    title: `${highlightText('Relax and Unwind:')} Dive into Your Favorite Stories Anytime with Peech`,
  },
  {
    cohorts: [Cohort.fiction],
    occupationGroup: [Occupation.retired, Occupation.other, Occupation.stayAtHome],
    title: `${highlightText('Immerse Yourself in Stories:')} Enjoy Your Leisure Time with Peech`,
  },
  {
    cohorts: [Cohort.disability],
    occupationGroup: [Occupation.serviceWorker, Occupation.officeWorker, Occupation.entrepreneur, Occupation.contentCreator, Occupation.creative],
    title: `${highlightText('Empower Your Day:')} Access Content Easily and Effectively with Peech`,
  },
  {
    cohorts: [Cohort.disability],
    occupationGroup: [Occupation.student],
    title: `${highlightText('Overcome Barriers:')} Learn and Achieve More with Peech`,
  },
  {
    cohorts: [Cohort.disability],
    occupationGroup: [Occupation.retired, Occupation.other, Occupation.stayAtHome],
    title: `${highlightText('Enhance Accessibility:')} Enjoy a Seamless Experience with Peech`,
  },
];

//TODO: do it based on the gender
const paywallImages: PaywallImage[] = [
  {
    cohort: Cohort.productivity,
    consumptionTimes: [ConsumptionTime.morning, ConsumptionTime.break],
    image: 'productivity_morning'
  },
  {
    cohort: Cohort.productivity,
    consumptionTimes: [ConsumptionTime.commute],
    image: 'productivity_commute'
  },
  {
    cohort: Cohort.productivity,
    consumptionTimes: [ConsumptionTime.workingOut],
    image: 'productivity_gym'
  },

  {
    cohort: Cohort.education,
    consumptionTimes: [ConsumptionTime.morning, ConsumptionTime.break],
    image: 'student_morning',
  },
  {
    cohort: Cohort.education,
    consumptionTimes: [ConsumptionTime.commute],
    image: 'student_commute',
  },
  {
    cohort: Cohort.education,
    consumptionTimes: [ConsumptionTime.workingOut],
    image: 'student_gym',
  },

  {
    cohort: Cohort.fiction,
    consumptionTimes: [ConsumptionTime.break, ConsumptionTime.morning, ConsumptionTime.commute, ConsumptionTime.workingOut],
    image: 'fiction',
  },

  {
    cohort: Cohort.disability,
    consumptionTimes: [ConsumptionTime.morning, ConsumptionTime.break],
    image: 'disability_morning'
  },
  {
    cohort: Cohort.disability,
    consumptionTimes: [ConsumptionTime.commute, ConsumptionTime.workingOut],
    image: 'disability_commute'
  },
];

const quizStepOrder: QuizStepKey[] = [
  'gender',
  'age',
  'amountInfo',
  'occupation',
  'goal',
  'goalInfo',
  'time',
  'duration',
  'timeStatsInfo', //after this we ask for the name
  'format',
  'formatInfo',
  'screenTime',
  'screenTimeSocialProof',
  'screenTimeQuestion',
  'screenTimeResult',
  'screenTimeImprovement',
  'resultInfo',
  'interests',
  'soundInfo',
  'speedInfo',
  'speedStatsInfo',
  'progressInfo',
];

type QuizSteps = {
  [K in QuizStepKey]: Quiz;
};

@Injectable({
  providedIn: 'root'
})
export class OnboardingFlowService {
  private steps: QuizSteps = quizSteps;

  private userCohort: Cohort = Cohort.productivity;
  private occupation: Occupation = Occupation.other;
  private times: ConsumptionTime[] = [];

  private gender: Gender;
  private age: string;
  private contentAmount: string;
  private contentFormats: string[] = [];
  private interests: Interest[] = [];
  private lastPlayedTrack: AudioTrack | null = null;

  private name: string | undefined;
  private topTitle = highlightText('Unlock Your Potential');

  constructor(private router: Router, private pixel: EventsService) {}

  goToStart() {
    this.router.navigate(['onboarding', 'start']);
  }

  handleFinishedStart() {
    amplitude.track('web_start_screen_viewed');
    this.router.navigate(['onboarding', 'quiz', 0]);
  }

  handleFinishedCustomization() {
    amplitude.track('web_customization_screen_viewed');
    this.router.navigate(['onboarding', 'checkout']);
  }

  handleFinishedName(name: string) {
    const indexToGo = quizStepOrder.indexOf('format');

    this.name = name;

    amplitude.track('web_name_entered', { name }); //TODO: maybe set name to user prop instead 
    
    this.router.navigate(['onboarding', 'quiz', indexToGo]);
  }

  handleFinishedStep(index: number, options: string[]) {
     const key = quizStepOrder[index];

     this.trackResponse(key, options);
     this.saveParameters(key, options);
     
     if (index + 1 >= quizStepOrder.length) {
      this.router.navigate(['onboarding', 'ready']);
     } else if (key === 'timeStatsInfo') {
      this.router.navigate(['onboarding', 'name']);
     } else {
      this.router.navigate(['onboarding', 'quiz', index + 1]);
     }
  }

  getName(): string | undefined {
    return this.name;
  }

  getQuizData(index: number): Quiz {
    const key = quizStepOrder[index];

    switch (key) {
      case 'amountInfo':
        const count = this.age === '👩‍💼 21-34' ? '4 Million' : (this.age === '👨‍💻 35-44' ? '3 Million' : '5 Million');
        const title = this.gender === Gender.male ? 'Men' : (this.gender === Gender.female ? 'Women' : 'Users');

        return {
          ...this.steps.amountInfo,
          title: `Join ${highlightText(`Over ${count} ${title}`)} Your Age Worldwide`,
        }

      case 'goalInfo':
        switch (this.userCohort) {
          case Cohort.disability:
            return {
              ...this.steps.goalInfo,
              title: `Peech Makes Information ${highlightText('Accessible to All')}`,
              subTitle: `Users with disabilities report that Peech helps them overcome barriers to reading, giving them greater access to information and improving their quality of life.`,
            }
          case Cohort.education:
            return {
              ...this.steps.goalInfo,
              title: `Students Using Peech See a ${highlightText('30% Improvement in Retention')}`,
              subTitle: `Learners who incorporate Peech into their study habits find it easier to recall information and excel in their academic pursuits.`,
            }
          case Cohort.fiction:
            return {
              ...this.steps.goalInfo,
              title: `Peech Turns Downtime into a ${highlightText('Journey of Imagination')}`,
              subTitle: `Fiction lovers who use Peech discover that listening to stories enhances relaxation and fuels creativity, making leisure time more fulfilling.`,
            }
          case Cohort.productivity:
            return {
              ...this.steps.goalInfo,
              title: `90% of Peech Users Feel ${highlightText('More in Control of Their Day')}`,
              subTitle: `By integrating Peech into their daily routine, users report a significant boost in productivity, allowing them to accomplish more with less stress.`,
            }
        }

      case 'timeStatsInfo':
        let multiplicator = '4x';
        let percent = '25%';

        if (this.contentAmount === 'About 15 mins') {
          multiplicator = '2x';
          percent = '50%';
        } else if (this.contentAmount === '15 - 30 mins') {
          multiplicator = '3x';
          percent = '33%';
        } else if (this.contentAmount === 'More than 1 hour') {
          multiplicator = '5x';
          percent = '20%';
        }

        return {
          ...this.steps.timeStatsInfo,
          title: `That\'s ${highlightText(`${multiplicator} more`)} than most people!`,
          subTitle: `You are in top ${percent} among your age.`,
        }

      case 'formatInfo':
        const list = this.removeEmojis(this.contentFormats.filter(format => !format.includes('Other')).join(', ').replace('My ', '')).toLowerCase();

        return {
          ...this.steps.formatInfo,
          title: `Great choice, ${this.name || 'Mate'}!`,
          subTitle: `We support uploading ${list} as well as almost any other type of text`, //TODO: exclude other
        }

      case 'interests':
        return {
          ...this.steps.interests,
          title: `What else are you interested in, ${this.name || 'Mate'}?`,
        }

      case 'soundInfo':
        let tracks: AudioTrack[] = [];

        this.interests.forEach(interest => {
          const track = interestTrackMap[interest];
          if (track) {
            tracks.push(track);
          }
        });

        if (tracks.length < 2) {
          const allTracks = Object.values(audioTracks);
          const additionalTracks = allTracks.filter(track => !tracks.includes(track));

          tracks.push(additionalTracks[0], additionalTracks[1]);
        }

        tracks = tracks.slice(0, 3);

        return {
          ...this.steps.soundInfo,
          audioTracks: tracks,
        };

      case 'speedInfo':
        if (!this.lastPlayedTrack) {
          this.lastPlayedTrack = audioTracks.history;
        }

        const speedTracks = [{
          ...this.lastPlayedTrack,
          title: `${this.lastPlayedTrack.title} x1.5`,
          audioPath: this.lastPlayedTrack.audioPath.replace('x1', 'x15'),
        }, {
          ...this.lastPlayedTrack,
          title: `${this.lastPlayedTrack.title} x2`,
          audioPath: this.lastPlayedTrack.audioPath.replace('x1', 'x2'),
        }];

        return {
          ...this.steps.speedInfo,
          audioTracks: speedTracks,
        }

      case 'speedStatsInfo':
        return {
          ...this.steps.speedStatsInfo,
          title: `${this.name || 'Mate'}, with Peech you can save ${highlightText('at least 2 hours')} on reading this week!`,
          userReview: this.getUserReview(),
        }

      case 'progressInfo':
        return {
          ...this.steps.progressInfo,
          subTitle: `Thanks for taking the quiz, ${this.name || 'Mate'} 👍`,
        }
    }

    return this.steps[key];
  }

  getQuizLength(): number {
    return quizStepOrder.length;
  }

  rememberLastPlayedTrack(audio: AudioTrack): void {
    amplitude.track('web_audio_demo_played', { title: audio.title });

    this.lastPlayedTrack = audio;
  }

  getTopTitle(): string {
    return this.topTitle;
  }

  getPaywallTitle(): string {
    const matchingTitles = paywallTitles.filter(
      (content) =>
        content.cohorts.includes(this.userCohort) &&
        content.occupationGroup.includes(this.occupation)
    );

    if (matchingTitles.length > 0) {
      return matchingTitles[0].title;
    }

    return `Enjoy ${highlightText('a personalized experience')} with Peech!`;
  }

  getPaywallMainImageClassName(): string {
    const matchingImages = paywallImages.filter(image =>
      image.cohort === this.userCohort
    );

    for (const time of this.times) {
      const match = matchingImages.find(image => image.consumptionTimes.includes(time));

      if (match) {
        return match.image;
      }
    }

    return `productivity_morning`;
  }

  private saveParameters(currentStepKey: QuizStepKey, selectedOptions: string[]) {
    switch (currentStepKey) {
      case 'gender':
        if (selectedOptions[0].includes('Male')) {
          this.gender = Gender.male;
        } else if (selectedOptions[0].includes('Female')) {
          this.gender = Gender.female;
        } else {
          this.gender = Gender.other;
        }
        break;
      case 'age':
        this.age = selectedOptions[0];
        break;
      case 'occupation':
        // '🧑‍🎓 Student',
        // '💻 Office / Freelancer',
        // '🛠 Trades & Service Worker',
        // '🎨 Creative',
        // '🚀 Entrepreneur',
        // '🎥 Content Creator',
        // '🏠 Stay-at-Home',
        // '🧓 Retired',
        // '🧑‍🤝‍🧑 Other'
        if (selectedOptions[0].includes('Student')) {
          this.occupation = Occupation.student;
        } else if (selectedOptions[0].includes('Office')) {
          this.occupation = Occupation.officeWorker;
        } else if (selectedOptions[0].includes('Service')) {
          this.occupation = Occupation.serviceWorker;
        } else if (selectedOptions[0].includes('Creative')) {
          this.occupation = Occupation.creative;
        } else if (selectedOptions[0].includes('Entrepreneur')) {
          this.occupation = Occupation.entrepreneur;
        } else if (selectedOptions[0].includes('Content Creator')) {
          this.occupation = Occupation.contentCreator;
        } else if (selectedOptions[0].includes('Stay-at-Home')) {
          this.occupation = Occupation.stayAtHome;
        } else if (selectedOptions[0].includes('Retired')) {
          this.occupation = Occupation.retired;
        } else {
          this.occupation = Occupation.other;
        }
        break;
      case 'goal':
        // 'Get a Raise or Promotion 💼',
        // 'Overcome Health Challenges 🌈',
        // 'Enter Top University 🎓',
        // 'Graduate with High Grades 🎓',
        // 'Read More & Enjoy Books 📖🎧',
        // 'Stay Focused & Get Things Done 🎯',
        if (selectedOptions[0].includes('University') || selectedOptions[0].includes('Graduate')) {
          this.userCohort = Cohort.education;
        } else if (selectedOptions[0].includes('Enjoy Books')) {
          this.userCohort = Cohort.fiction;
        } else if (selectedOptions[0].includes('Health Challenges')) {
          this.userCohort = Cohort.disability;
        } else {
          this.userCohort = Cohort.productivity;
        }

        switch (selectedOptions[0]) {
          case 'Get a Raise or Promotion 💼':
            this.topTitle = `${highlightText('Achieve Career Success')}`;
            break;
          case 'Overcome Health Challenges 🌈':
            this.topTitle = `${highlightText('Empower Your Life')}`;
            break;
          case 'Enter Top University 🎓':
            this.topTitle = `${highlightText('Succeed in Your Education')}`;
            break;
          case 'Graduate with High Grades 🎓':
            this.topTitle = `${highlightText('Excel in Your Studies')}`;
            break;
          case 'Read More & Enjoy Books 📖🎧':
            this.topTitle = `${highlightText('Enjoy Great Stories')}`;
            break;
          case 'Stay Focused & Get Things Done 🎯':
            this.topTitle = `${highlightText('Maximize Your Productivity')}`;
            break;
        }
        break;
      case 'time':
        const timesMapping: { [key: string]: ConsumptionTime } = {
          'In the morning': ConsumptionTime.morning,
          'On my commute': ConsumptionTime.commute,
          'While working out': ConsumptionTime.workingOut,
          'While taking a break': ConsumptionTime.break,
        };

        this.times = selectedOptions.map(option => timesMapping[option]);

        break;
      case 'duration':
        this.contentAmount = selectedOptions[0];
        break;
      case 'format':
        this.contentFormats = selectedOptions;
        break;
      case 'interests':
        this.interests = selectedOptions as Interest[];
        break;
    }
  }

  private getUserReview(): UserReview {
    const review = {
      name: 'Jerin Barnes',
      avatarUrl: 'assets/img/avatars/5.png',
    }
  
    switch (this.userCohort) {
      case Cohort.disability:
        return {
          ...review,
          message: 'As someone with dyslexia, Peech has been a lifesaver. It makes reading so much easier and more accessible. I can finally enjoy books and articles without struggling.'
        }
      case Cohort.education:
        return {
          ...review,
          message: 'Peech has given me the edge in my academic journey, helping me absorb concepts on the go.',
        };
      case Cohort.fiction:
        return {
          ...review,
          message: 'Peech has become my go-to app for unwinding with a good story. I can finally enjoy more books during my downtime, and the experience is just as immersive as reading.',
        };
      case Cohort.productivity:
        return {
          ...review,
          message: 'Peech is my secret weapon for staying productive while running my business. It lets me absorb key information without slowing down.',
        };
    }
  }

  private removeEmojis(string: string): string {
    return string.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1FA70}-\u{1FAFF}]/gu, '').trim();
  }

  private trackResponse(currentStepKey: QuizStepKey, options: string[]): void {
    switch (currentStepKey) {
      case 'gender':
        amplitude.track('web_gender_selected', { gender: options[0] });
        break;
      case 'age':
        amplitude.track('web_age_selected', { age: options[0] });
        break;
      case 'amountInfo':
        amplitude.track('web_world_users_screen_viewed');
        break;
      case 'occupation':
        amplitude.track('web_occupation_selected', { occupation: options[0] });
        break;
      case 'goal':
        amplitude.track('web_goal_selected', { goal: options[0] });
        break;
      case 'goalInfo':
        amplitude.track('web_goal_info_screen_viewed');
        break;
      case 'time':
        amplitude.track('web_listening_time_selected', { times: options });
        break;
      case 'duration':
        amplitude.track('web_reading_duration_selected', { daily_reading_duration: options[0] });
        break;
      case 'timeStatsInfo':
        amplitude.track('web_time_stats_screen_viewed');
        break;
      case 'format':
        amplitude.track('web_formats_to_listen_selected', { formats: options });
        break;
      case 'formatInfo':
        amplitude.track('web_formats_info_screen_viewed');
        break;
      case 'resultInfo':
        amplitude.track('web_positive_changes_screen_viewed');
        break;
      case 'interests':
        amplitude.track('web_interests_selected', { interests: options });
        break;
      case 'soundInfo':
        amplitude.track('web_audio_examples_screen_viewed');
        break;
      case 'speedInfo':
        amplitude.track('web_audio_speed_examples_screen_viewed');
        break;
      case 'speedStatsInfo':
        amplitude.track('web_time_saving_screen_viewed');
        break;
      case 'progressInfo':
        amplitude.track('web_progress_screen_viewed');
        break;
      default:
        console.log(`Warning: We didn't track ${currentStepKey}`);
    }
  }
}
