import { track } from '@amplitude/analytics-browser';
import { Component, OnInit } from '@angular/core';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-onboarding-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit {
  constructor(private flowService: OnboardingFlowService) {}

  ngOnInit(): void {
    track('web_start_onboarding_view');
  }

  continueButtonClicked(): void {
    this.flowService.handleFinishedStart();
  }
}
