<div
    class="pt-5 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="row justify-content-center">
        <h2 class="display-6 text-center">Hello, {{name}}!</h2>
        <h5 class="text-center mt-10">
            {{message}} Get <span class="main-color">unlimited listening</span> with a <span class="red-color">limited time 50% discount</span>. Cancel anytime.
        </h5>
    </div>
    <div class="row checkout-container mt-10">
        <div class="checkout d-flex flex-column justify-content-center align-items-center">
            <!-- <div class="row"> -->
                <mat-spinner *ngIf="isLoading" mode="indeterminate" diameter='50'></mat-spinner>

                <div *ngIf="!isLoading" class="col-12 col-md-6">
                    <app-checkout [discount]="0.5" [placement]="'discount'" [email]="email"></app-checkout>
                </div>
                <!-- <div class="col-12 col-md-4 pt-30">
                    <div class="listbox-container">
                        <ul class="listbox">
                            <li class="d-flex align-items-center mb-20">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Listen to unlimited research papers</span>
                            </li>
                            <li class="d-flex align-items-center mb-20">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Take notes while you listen (with 1 click)</span>
                            </li>
                            <li class="d-flex align-items-center mb-20">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Upload from mobile or desktop</span>
                            </li>
                            <li class="d-flex align-items-center mb-20">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Pick which sections to listen to</span>
                            </li>
                            <li class="d-flex align-items-center">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Bonus: Listen to web pages & emails</span>
                            </li>
                        </ul>
                    </div>
                </div> -->
        </div>
    </div>
</div>