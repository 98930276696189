<div class="quiz-container">
  <div class="progress-bar">
    <div class="progress-thumb" [style.width.%]="100 * (currentIndex + 1) / stepsCount"></div>
  </div>
  <div class="quiz-step">
    <div class="icon" *ngIf="data.topIcon">{{ data.topIcon }}</div>
    <h3 [innerHTML]="data.title"></h3>
    <div class="subtitle" *ngIf="data.subTitle && !(data.isVoting || data.lottieFilePath || data.rive || data.videoFilePath || data.imageFilePath || data.headline)">
      {{ data.subTitle }}
    </div>
    <div *ngIf="data.options || data.headline || data.lottieFilePath || data.rive || data.videoFilePath || data.imageFilePath || data.audioTracks || data.hasSocialProof || data.userReview" class="content-container d-flex flex-column mt-20">
      
      <form [formGroup]="form">
        <ng-container *ngIf="data.options && !data.isChips">
            <!-- Multiple selection list -->
          <mat-selection-list
            *ngIf="data.isMultiple"
            [ngClass]="{
              'grid-list-3': data.options.length === 3,
              'grid-list': data.isTilesDisplay
            }"
            [multiple]="true"
            formControlName="selectedOptions"
            [disableRipple]="true"
            (selectionChange)="optionClicked()"
          >
            <mat-list-option *ngFor="let option of data.options" [value]="option.title">
              <app-quiz-list-option [option]="option"></app-quiz-list-option>
            </mat-list-option>

          </mat-selection-list>

          <!-- Single selection list -->
          <mat-selection-list
            *ngIf="!data.isMultiple"
            [ngClass]="{
              'grid-list-3': data.options.length === 3,
              'grid-list': data.isTilesDisplay
            }"
            [multiple]="false"
            formControlName="selectedOptions"
            [disableRipple]="true"
            (selectionChange)="optionClicked()"
          >
            <mat-list-option *ngFor="let option of data.options" [value]="option.title">
              <app-quiz-list-option [option]="option"></app-quiz-list-option>
            </mat-list-option>

          </mat-selection-list>

        </ng-container>

        <ng-container *ngIf="data.options && data.isChips">
          <mat-chip-listbox
            formControlName="selectedOptions"
            [multiple]="true"
            aria-label="Options selection"
          >
            <mat-chip-option *ngFor="let option of data.options" [value]="option.title">
              {{ option.title }}
            </mat-chip-option>
          </mat-chip-listbox>
        </ng-container>
      </form>

      <ng-container *ngIf="data.audioTracks">
        <app-audio-track
          *ngFor="let track of data.audioTracks"
          #audioTrackRef
          [data]="track"
          (onPlayed)="onPlay($event, track)"
        ></app-audio-track>
      </ng-container>

      <ng-lottie
        *ngIf="data.lottieFilePath"
        [options]="{ path: data.lottieFilePath, loop: false }"
        containerClass="animation"
      ></ng-lottie>

      <canvas
        #animation
        class="animation"
        width="640"
        [height]="640 / (data.rive?.ratio || 1)"
        [ngClass]="{ 'd-none': !data.rive }"
        [ngStyle]="{ 'background-image': getBackgroundImageUrl() }"
      ></canvas>

      <video
        *ngIf="data.videoFilePath"
        class="video"
        [src]="data.videoFilePath + '#t=0.1'"
        type="video/mp4"
        autoplay
        muted
        playsinline
      ></video>

      <div *ngIf="data.imageFilePath" class="image" [ngStyle]="{ 'background-image': getBackgroundImageUrl() }"></div>

      <h1 *ngIf="data.headline" class="display-1 headline" [class.negative]="data.isNegative">{{ data.headline }}</h1>

      <div *ngIf="data.subTitle && (data.isVoting || data.lottieFilePath || data.rive || data.videoFilePath || data.imageFilePath || data.headline)" class="subtitle bottom">
        {{ data.subTitle }}
      </div>

      <app-social-proof *ngIf="data.hasSocialProof"></app-social-proof>

      <app-user-review
        *ngIf="data.userReview"
        [name]="data.userReview.name"
        [avatarUrl]="data.userReview.avatarUrl"
        [message]="data.userReview.message"
        [logoUrl]="data.userReview.logoUrl"
      ></app-user-review>
    </div>
  </div>

  <div *ngIf="(!data.options || data.isChips || data.isMultiple) && !data.isVoting" class="quiz-footer gradient d-flex justify-content-center">
    <button class="primary-button" [disabled]="data.isRequired && (!form.value.selectedOptions || form.value.selectedOptions.length === 0)" (click)="buttonClicked()">
      Continue
    </button>
  </div>

  <div *ngIf="data.isVoting" class="quiz-footer d-flex justify-content-center align-items-center">
    <button class="voting-button" (click)="voteClicked('no')">👎</button>
    <button class="voting-button" (click)="voteClicked('miss')">🤷‍♂️</button>
    <button class="voting-button" (click)="voteClicked('yes')">👍</button>
  </div>
</div>
