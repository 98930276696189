<div class="payment-container p-30">
    <h4 class="text-center"><span class="main-color">91% of users</span> stay with us after initial 30-day subscription</h4>

    <div *ngIf="isLoading" class="w-100 d-flex p-30 justify-content-center">
        <mat-spinner mode="indeterminate" diameter='50'></mat-spinner>
    </div>

    <form #stripeForm class="w-100 d-flex flex-column mt-10" [class.hidden]="isLoading">
        <h5 class="text-center">Select payment method</h5>
        <div class="mt-10" #applePayButton *ngIf="isApplePayAvailable"></div>
        <p class="text-center mt-15" *ngIf="isApplePayAvailable">or enter details:</p>
        <div #stripeEmail class="mb-20">
            <span *ngIf="email" class="muted-color">Email: {{email}}</span>
        </div>
        <div #stripeElement></div>
        <button mat-raised-button class="pulsing fw-semibold tagus w-100 mt-25 border-radius">{{purchaseButtonTitle}}</button>
        <div class="text-hint mt-20 mb-20">We’ve automatically applied discount to your first subscription price. You will be charged {{product.priceLabel}}. Please note that your subscription will be automatically renewed at full price of {{product.fullPriceLabel}} at the end of chosen subscription term. Terms and Conditions apply. If you want to manage your subscription, you may do so via your personal account.</div>
      </form>
      <!-- <p class="text-center">or</p>
      <div class="paypal-container" #paypal></div> -->
</div>