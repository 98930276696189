import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Rive } from '@rive-app/canvas';
import { tap } from 'rxjs';
import { AudioTrack, AudioTrackComponent } from 'src/app/shared/audio-track/audio-track.component';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';
import { Quiz } from '../../services/quiz';

@Component({
  selector: 'app-onboarding-quiz-step',
  templateUrl: './quiz-step.component.html',
  styleUrls: ['./quiz-step.component.scss']
})
export class QuizStepComponent implements OnInit, AfterViewInit {
  data: Quiz;
  form: FormGroup;

  currentIndex: number;
  stepsCount: number;

  @ViewChildren('audioTrackRef') audioTracks!: QueryList<AudioTrackComponent>;
  @ViewChild('animation') riveAnimation: ElementRef;

  private riveInstance: Rive | undefined;
  private isAnimationChecked = false;
  private isViewInitted = false;

  constructor(
    private route: ActivatedRoute,
    private flowService: OnboardingFlowService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.stepsCount = this.flowService.getQuizLength();

    this.route.paramMap
      .pipe(
        tap(params => {
          this.currentIndex = parseInt(params.get('index') || '0', 10);
          this.data = this.flowService.getQuizData(this.currentIndex);

          this.checkAnimation();

          if (!this.data) {
            this.flowService.goToStart();
            return;
          }

          this.form = this.fb.group({ selectedOptions: [''] });
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.isViewInitted = true;

    if (!this.isAnimationChecked) {
      this.checkAnimation();
    }
  }

  buttonClicked(): void {
    const selectedOptions = this.form.value.selectedOptions;

    if (this.data.isRequired && (!selectedOptions || !selectedOptions.length)) {
      return;
    }

    this.finish(selectedOptions);
  }

  voteClicked(choice: 'yes' | 'no' | 'miss'): void {
    this.finish([choice]);
  }

  optionClicked(): void {
    if (this.data.isMultiple) {
      return;
    }

    this.finish(this.form.value.selectedOptions);
  }

  onPlay(currentTrack: AudioTrackComponent, track: AudioTrack): void {
    this.audioTracks.forEach(trackComponent => {
      if (trackComponent !== currentTrack) {
        trackComponent.pause();
      }
    });
    this.flowService.rememberLastPlayedTrack(track);
  }

  getBackgroundImageUrl(): string {
    if (this.data.imageFilePath) {
      return `url(${this.data.imageFilePath})`;
    }

    if (this.data.rive?.backgroundImagePath) {
      return `url(${this.data.rive.backgroundImagePath})`;
    }

    return '';
  }

  private checkAnimation(): void {
    if (!this.isViewInitted) {
      return;
    }

    this.riveInstance?.stop();
    this.riveInstance?.cleanup();

    if (this.data.rive) {
      const canvas = this.riveAnimation.nativeElement;

      this.riveInstance = new Rive({
        src: `assets/rive/${this.data.rive.fileName}.riv`,
        canvas,
        autoplay: true,
        animations: this.data.rive.animations,
        onLoad: () => this.riveInstance?.resizeToCanvas(), //resizeDrawingSurfaceToCanvas(),
      });
    }

    this.isAnimationChecked = true;
  }

  private finish(options: string[]): void {
    this.flowService.handleFinishedStep(this.currentIndex, options);
  }
}
