<div class="start-container d-flex flex-column align-items-center justify-content-center text-center">
    <div class="content d-flex flex-column align-items-center justify-content-center light-green-color border-radius p-20" (click)="continueButtonClicked()">
        <h2 class="main-color">20+ million people</h2>
        <div class="subtitle">already use Peech App</div>

        <div class="cite bg-white border-radius p-25 d-flex flex-column justify-content-center mt-15">
            <div class="d-flex content flex-grow-1">
                <div class="d-flex">
                    <div class="quotas-image image mt-10"></div>
                </div>
                <div class="flex-grow-1 d-flex flex-column justify-content-space-between">
                    <p class="lead">Peech is a text-to-speech app that actually sounds good</p>
                </div>
            </div>
            <div class="mt-15 d-flex justify-content-center">
                <div class="media-logo image"></div>
            </div>
        </div>

        <div class="subtitle mt-15">mentioned in</div>
        <div class="media-logos image mt-5"></div>

    </div>

    <div class="footer d-flex flex-column align-items-center justify-content-center p-15">
        <button class="primary-button" (click)="continueButtonClicked()">Get Started</button>
        <div class="info gray-color">Florinis 7, Greg Tower, 2nd Floor, 1065, Nicosia, Cyprus</div>
    </div>
</div>
