<div class="p-30 d-flex flex-column align-items-center">
    <h6 class="display-6 text-center">{{title}}</h6>
    <canvas 
        *ngIf="rive"
        #animation
        class="w-100 mt-5 animation"
        width="640"
        [height]="480 / rive.ratio"
    ></canvas>
    <div class="fs-16 fw-light" [ngClass]="{ 'mt-20': rive }">{{message}}</div>
    <div mat-dialog-actions class="d-flex buttons justify-content-center mt-20">
        <button *ngIf="isConfirmation" mat-raised-button mat-dialog-close (click)="close(false)" class="">Cancel</button>
        <button mat-raised-button mat-dialog-close (click)="close(true)" class="tagus">{{okButtonTitle || 'Ok'}}</button>
    </div>
</div>
