import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '../guards/auth.guard';
import { EmailComponent } from './components/email/email.component';
import { NameComponent } from './components/name/name.component';
import { PaywallComponent } from './components/paywall/paywall.component';
import { PreparationComponent } from './components/preparation/preparation.component';
import { QuizStepComponent } from './components/quiz-step/quiz-step.component';
import { SignInWithEmailComponent } from './components/sign-in-with-email/sign-in-with-email.component';
import { StartComponent } from './components/start/start.component';
import { OnboardingRootComponent } from './onboarding-root.component';

const routes: Routes = [
  {
    path: '',
    component: OnboardingRootComponent,
    children: [
      { 
        path: '', 
        redirectTo: 'start', 
        pathMatch: 'full' 
      },
      {
        path: 'start',
        component: StartComponent,
      },
      { 
        path: 'quiz', 
        redirectTo: 'quiz/0', 
        pathMatch: 'full' 
      },
      {
        path: 'quiz/:index',
        component: QuizStepComponent,
      }, 
      {
        path: 'checkout',
        canActivate: [authGuard],
        component: PaywallComponent,
      }, {
        path: 'login',
      //  component: LoginComponent,
         component: EmailComponent,
      }, 
      // {
      //   path: 'success',
      //   component: SuccessComponent,
      // }, {
      //   path: 'failure',
      //   component: FailureComponent,
      // }, 
      {
        path: 'ready',
        component: PreparationComponent,
      }, {
        path: 'email-sign-in',
        component: SignInWithEmailComponent,
      }, {
        path: 'name',
        component: NameComponent,
      }
    ],
  }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ]
})
export class OnboardingRoutingModule { }
