import { Component, Input } from '@angular/core';

export interface UserReview {
  name: string;
  message: string;
  avatarUrl: string;
  title?: string;
  logoUrl?: string;
}

@Component({
  selector: 'app-user-review',
  templateUrl: './user-review.component.html',
  styleUrl: './user-review.component.scss'
})
export class UserReviewComponent {
  @Input() name: string;
  @Input() message: string;
  @Input() title: string;
  @Input() avatarUrl: string;
  @Input() logoUrl: string = '';
}
