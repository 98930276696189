import * as amplitude from '@amplitude/analytics-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter, takeWhile, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboarding-root',
  templateUrl: './onboarding-root.component.html',
  styleUrl: './onboarding-root.component.scss'
})
export class OnboardingRootComponent implements OnInit, OnDestroy {
  isLogoShown: boolean = true;
  isActive: boolean = true;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.isLogoShown = !this.router.url.includes('checkout');

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(() => {
          this.isLogoShown = !this.router.url.includes('checkout')
      }),
      takeWhile(() => this.isActive),
    ).subscribe();

    this.setIcons();

    this.startAnalytics();
    //this.pixel.track('first_open'); //currently it happens in main app component
  }

  startAnalytics(): void {
    amplitude.init(environment.amplitudeKey, {
      defaultTracking: true,
      appVersion: environment.appVersion,
      autocapture: {
        pageViews: false,
      }
    });

    amplitude.track('web_onboarding_started');
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  private setIcons() {
    this.matIconRegistry.addSvgIcon('visibility_off', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/onboarding/visibility_off.svg'));
    this.matIconRegistry.addSvgIcon('visibility_on', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/onboarding/visibility_on.svg'));
    // this.matIconRegistry.addSvgIcon('envelope', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/onboarding/envelope.svg'));
    // this.matIconRegistry.addSvgIcon('play', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/onboarding/play_icon.svg'));
    // this.matIconRegistry.addSvgIcon('pause', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/onboarding/pause_icon.svg'));
  }
}
