<div class="paywall-container">
  <div class="header">
    <div class="header-text">
      <div class="header-title"><span class="red-color fw-semibold">{{discount * 100}}%</span> discount is reserved for:</div>
      <div class="header-timer fw-semibold">{{ timer }}</div>
    </div>
    <button class="primary-button header-button" (click)="payButtonClicked()">{{purchaseButtonTitle}}</button>
  </div>

  <div class="d-flex mt-20 justify-content-center w-100">
    <div class="top-image-square-container">
      <div class="image" [class]="topImageClassName"></div>
    </div>
  </div>

  <h1 class="display-6 mt-20"[innerHTML]="topTitle"></h1>

  <div class="mt-20 w-100 text-center subtitle">Choose your plan</div>

  <div class="col-12 col-md-6 mt-20">
    <app-checkout #checkout placement="main" [email]="email" [discount]="0.4" (purchaseButtonTitle)="handlePurchaseButtonTitle($event)"></app-checkout>
  </div>

  <div class="mt-20 d-flex flex-column align-items-center">
    <div class="payment-promo">Safe, secure and easy way to pay</div>
    <div class="options-logos"></div>
  </div>

  <!-- <h1 class="display-6 mt-20">What you get</h1> -->

  <div class="row align-items-center mt-30">
    <div class="col-lg-7 mt-20 mb-20">
        <h1 class="display-4" [innerHTML]="title"></h1>
        <div class="lead">Save hours and get your eyes some rest, in your spare time</div>
    </div>
    <div class="col-lg-5 d-flex justify-content-center mt-20 mb-20">
      <img class="top-image" src="assets/img/top2.png">
    </div>
  </div>
  
    <div class="row mt-30">
        <h1 class="display-6">The world’s first mobile app <span class="main-color">built for listening with users in mind</span></h1>
    </div>
    <div class="row align-items-center mt-20">
        <div class="col-lg-6 col-sm-12 d-flex justify-content-center">
            <img class="top-image" src="assets/img/resources.png">
        </div>
        <div class="col-lg-6 col-sm-12 mt-20">
            <div class="lead">We've carefully hand-crafted the app, including:</div>
            <div class="mt-15 ps-20 text-list">
                <div>Lifelike voices, complete with emotion and intonation</div>
                <div>Easily pronounces technical words in any field</div>
                <div>Numerous formats supported: PDFs, Docs, E-books, Web Articles and even Photos</div>
                <div>More than 60 languages supported</div>
                <div>Ability to group the items into playlists</div>
            </div>
        </div>
    </div>

  <h1 class="display-6 mt-20">Try <span class="main-color">Peech Premium</span> with introductory discount</h1>
  <div class="subscription-info">
    <div class="subscription-text">Enjoy unlimited access for selected period. Love it and keep investing in yourself, or simply cancel before the period ends so you won’t be charged repeatedly.</div>
  </div>

  <button class="primary-button pay-button" (click)="payButtonClicked()">{{purchaseButtonTitle}}</button>

  <h1 class="display-6 mt-20">What our users say</h1>

  <div class="list-container">
    <app-user-review
      name="Alena Mango"
      avatarUrl="assets/img/avatars/1.png"
      message="I’m so happy with this app, I can now listen to any book or notes I have. I also like the voices and the speed options 👌🏼✅📖"
    ></app-user-review>

    <app-user-review
      name="Justin Geidt"
      avatarUrl="assets/img/avatars/4.png"
      message="Peech is seriously such a game changer! I love to use it like an audiobook so that I can listen to my textbooks while I’m driving + doing every day tasks"
    ></app-user-review>

    <app-user-review
      name="Glenn Levin"
      avatarUrl="assets/img/avatars/3.png"
      message="Super unique tool to help you study. It saves you a ton of time and energy, by allowing you to turn anything into an audio text that can be read!"
    ></app-user-review>
  </div>

  <button class="primary-button pay-button" (click)="payButtonClicked()">{{purchaseButtonTitle}}</button>

  <h1 class="display-6 mt-20">Start growing with Peech now</h1>
  <div class="list-container">
    <div class="stats">
      <div class="stats-icon">👑</div>
      <div class="stats-title">20 Million</div>
      <div class="stats-text">Users on all platforms</div>
    </div>
    <div class="stats">
      <div class="stats-icon">⭐️</div>
      <div class="stats-title">4.8 Stars</div>
      <div class="stats-text">Average ratings on App Store</div>
    </div>
    <div class="stats">
      <div class="stats-icon">🌈</div>
      <div class="stats-title">91%</div>
      <div class="stats-text">Of Peech members create a better reading habit*</div>
    </div>
  </div>

    <div class="row mt-30">
        <h1 class="display-6">Frequently Asked Questions</h1>
    </div>
    <div class="col-lg-6 col-md-12 mt-25">
        <mat-accordion class="tagus-basic-expansion mb-20">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        How do I cancel my account?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>It's easy! Just log into your account here, go to "Settings", and click the Cancel button.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        What happens if I cancel during the 2 week trial?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>You will be charged just $0.99, no full price!</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Can it pronounce difficult words, like "Mesothelioma" or "Diphenhydramine"?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Yep. If our audio ever mispronounces a word, just let us know and we'll fix it.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        I don't want to listen to all the references and citations. Can your app skip those?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Yes, we skip those, so you can focus on the core material.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        I only want to listen to useful content of the web article. How do I do that?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>We pull only useful text from any web page or document!</p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

  <div class="hint mt-20 fs-14">*Based on survey data from Peech users</div>

  <div class="muted-color mt-20 mb-30 fs-12"><a target="_blank" href="https://www.getpeech.com/terms">Terms & Conditions</a> and <a target="_blank" href="https://www.getpeech.com/privacy">Privacy Policy</a></div>
</div>
