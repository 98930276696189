import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ModalData {
  title: string;
  message: string;
  isConfirmation?: boolean;
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  title: string;
  message: string;
  isConfirmation: boolean | undefined = false;

  constructor(
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: ModalData,
    ) {
      this.title = data.title;
      this.message = data.message;
      this.isConfirmation = data.isConfirmation;
    }

  close(isOk: boolean) {
    this.dialogRef.close(isOk);
  }
}
