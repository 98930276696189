import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';
import { AuthService } from './services/auth.service';
import { CustomizerSettingsService } from './services/customizer-settings.service';
import { EventsService } from './services/events.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'Peech';

    isBlankPage: boolean = false;

    private blankPageRoutes = [
        '/error-500',
        '/authentication/reset-password',
        '/authentication/login',
        '/authentication/register',
        '/authentication/forgot-password',
        '/authentication/signin-signup',
        '/authentication/logout',
        '/authentication/confirm-mail',
        '/login',
        '/w2a/redirect',
        '/w2a/go-to-store',
        '/onboarding/',
        //TODO: think about /user-discount
    ];

    constructor(
        public router: Router,
        public themeService: CustomizerSettingsService,
        private authService: AuthService,
        private eventsService: EventsService,
    ) {}

    ngOnInit(): void {
        this.eventsService.track('first_open');

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            tap(() => {
                this.isBlankPage = this.blankPageRoutes.some(route => this.router.url.includes(route))
            }),
        ).subscribe();
    }
}