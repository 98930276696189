import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { loadStripe, StripeElements, StripeError } from '@stripe/stripe-js';
//import { loadScript } from '@paypal/paypal-js';
import { track } from '@amplitude/analytics-browser';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentIntentResult, SetupIntentResult, Stripe } from '@stripe/stripe-js';
import { filter, from, fromEvent, switchMap, take, tap } from 'rxjs';
import { ModalComponent, ModalData } from 'src/app/components/modal/modal.component';
import { LoadingService } from 'src/app/services/loading.service';
import { environment } from 'src/environments/environment';
import { ProductView } from '../checkout/checkout.component';

export interface PaymentData {
  product: ProductView;
  buttonTitle: string;
  clientSecret: string; //stripe
  isSetupIntent: boolean; //stripe
  email?: string;
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrl: './payment.component.scss'
})
export class PaymentComponent implements OnInit {
  //@ViewChild('paypal') paypalElement!: ElementRef;
  @ViewChild('stripeElement') stripeElement!: ElementRef;
  @ViewChild('stripeForm') stripeForm!: ElementRef;
  @ViewChild('stripeEmail') stripeEmail!: ElementRef;

  @ViewChild('applePayButton') applePayButton!: ElementRef;

  isLoading: boolean = true;

  email: string | null;
  product: ProductView;

  isApplePayAvailable: boolean = true;
  purchaseButtonTitle = 'Continue';

  private clientSecret: string; //stripe
  private isSetupIntent: boolean; //stripe

  private stripeElements: StripeElements | undefined;
  private stripeLoaded$ = from(loadStripe(environment.stripePubKey));

  constructor(
    private router: Router,
    //private pixel: EventsService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PaymentComponent, void>,
    @Inject(MAT_DIALOG_DATA) data: PaymentData,
    ) {
      this.email = data.email ||  null;
      this.product = data.product;
      this.clientSecret = data.clientSecret;
      this.isSetupIntent = data.isSetupIntent;
      this.purchaseButtonTitle= data.buttonTitle;
    }

  ngOnInit(): void {
    this.stripeLoaded$
      .pipe(
        //tap(() => this.isLoading = false), //TODO: actually we should wait a bit more till the form is fully loaded
        filter(stripe => !!stripe),
        take(1),
        tap((stripe) => this.createMainPayment(stripe!)),
        tap((stripe) => this.createAdditionalPaymentButton(stripe!)),
        switchMap((stripe) => this.watchStripe(stripe!)),
      )
      .subscribe();
  }

  close() {
    this.dialogRef.close();
  }

  private createMainPayment(stripe: Stripe) {
    this.stripeElements = stripe.elements({
      clientSecret: this.clientSecret,
      appearance: {
        theme: 'stripe',
      },
      loader: 'always'
    });

    this.stripeElements?.create('payment', {
      paymentMethodOrder: ['card', 'paypal'], //'apple_pay', 'google_pay', 
    }).mount(this.stripeElement.nativeElement);

    if (!this.email) {
      this.stripeElements?.create('linkAuthentication').mount(this.stripeEmail.nativeElement);
    }
  }

  private createAdditionalPaymentButton(stripe: Stripe) {
    //apple pay or google pay
    const paymentRequest = stripe!.paymentRequest({
      country: 'US', // Your country code
      currency: 'usd', // Your currency
      total: {
        label: 'Total', // Label for the total amount
        amount: 100 * (this.product.setupFeePriceUsd || 0) || 100 * (this.product.introductoryPriceUsd || 0) || 99, // Amount in cents
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    const applePayElement = this.stripeElements?.create('paymentRequestButton', {
      paymentRequest,
      style: {
        paymentRequestButton: {
          height: '48px',
          theme: 'dark',
        }
      }
    });

    // Check if the paymentRequest can be made (e.g., if Apple Pay is available)
    paymentRequest.canMakePayment().then((result) => {
      this.isLoading = false; //TODO: make sure there is no better api in stripe to wait

      if (result) {
        applePayElement?.mount(this.applePayButton.nativeElement);

        paymentRequest.on('paymentmethod', async (event) => {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            this.clientSecret,
            {
              payment_method: event.paymentMethod.id,
              // Shipping details, billing details, etc., can be included here
            },
            { handleActions: true }
          );

          if (error) {
            event.complete('fail');
            this.handleError(error);
          } else {
            event.complete('success');
            this.goToSuccess();
          }
        });
      } else {
        this.isApplePayAvailable = false;
      }
    });
  }

  private watchStripe(stripe: Stripe) {
    const confirmParams = {
      elements: this.stripeElements!,
      confirmParams: {
        return_url: `${location.origin}/result`
      },
      redirect: 'if_required' as 'if_required'
    };

    return fromEvent(this.stripeForm.nativeElement, 'submit')
      .pipe(
        tap((event: any) => event.preventDefault()),
        tap(() => this.loadingService.start()),
        switchMap(() => this.isSetupIntent ? from(stripe.confirmSetup(confirmParams)) : from(stripe.confirmPayment(confirmParams))),
        tap((result: PaymentIntentResult | SetupIntentResult) => {
          this.loadingService.finish();

          if (result.error) {
            return this.handleError(result.error);
          }
  
          console.log((result as any).setupIntent || (result as any).paymentIntent);
    
          this.goToSuccess();
        }),
      );
  }

  private goToSuccess() {
    this.close();
    this.router.navigate(['result']);
  }

  private handleError(error: StripeError) {  
    console.log(error);

    if (error.type === 'validation_error') {
      return;
    }

    track('web_subscribe_failure', {
      error
    });

    this.stripeElements?.getElement('payment')?.clear();

    this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      data: {
        title: 'Sorry',
        message: error.message || 'Your payment failed. Please try again.',
      }
    })
      .afterClosed()
      .subscribe();
  }

  // private initPayPal(): void {
  //   from(loadScript({ 'client-id': environment.payPalClientId, vault: true }))
  //     .pipe(
  //       filter(paypal => !!paypal),
  //       take(1),
  //       switchMap(paypal => from(paypal!.Buttons!({
  //         style: {
  //           label: 'paypal',
  //           color: 'gold',
  //           layout: 'horizontal',
  //           //height: 80,
  //         },
  //         createSubscription: (data, actions) => {
  //           //track('web_checkout_paypal_click');

  //           return actions.subscription.create({
  //             plan_id: this.selectedProductControl.value?.paypalPlanId || 'error',
  //             //custom_id: `${this.data.userId}`, //TODO: think about it
  //           });
  //         },
  //         onApprove: (data, actions) => {
  //           console.log('onApprove - transaction was approved, but not authorized', data, actions);
  //           //actions.order!.get().then((details: any) => {

  //           //this.dialogRef.close(CheckoutResult.success);

  //           return Promise.resolve();
  //         },  
  //         onError: error => {
  //           console.log(error);
  //           //this.dialogRef.close(CheckoutResult.failure);
  //         }
  //       }).render(this.paypalElement.nativeElement))),
  //     )
  //     .subscribe();
  // }

}
