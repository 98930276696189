import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, takeWhile, tap } from 'rxjs';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-onboarding-preparation',
  templateUrl: './preparation.component.html',
  styleUrls: ['./preparation.component.scss']
})
export class PreparationComponent implements OnInit, OnDestroy {
  value = 0;

  private isActive = true;

  private duration = Math.random() * 4000 + 5000;
  private intervalDuration = 50;

  constructor(private flowService: OnboardingFlowService) {}

  ngOnInit(): void {
    const steps = this.duration / this.intervalDuration;

    interval(this.intervalDuration)
      .pipe(
        tap((index) => {
          const t = index / steps;
          const easedProgress = this.cubicEaseOut(t);

          this.value = Math.min(Math.round(easedProgress * 100), 100);

          if (index >= steps) {
            this.isActive = false;
            this.flowService.handleFinishedCustomization();
            return;
          }
        }),
        takeWhile(() => this.isActive),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  private cubicEaseOut(t: number): number {
    return --t * t * t + 1;
  }
}
