import { track } from '@amplitude/analytics-browser';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { filter, interval, take, takeWhile, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EventsService } from 'src/app/services/events.service';
import { CheckoutComponent } from 'src/app/shared/checkout/checkout.component';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';

@Component({
  selector: 'app-onboarding-paywall',
  templateUrl: './paywall.component.html',
  styleUrls: ['./paywall.component.scss']
})
export class PaywallComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('checkout') checkoutComponent: CheckoutComponent;

  discount: number = 0.4;
  timer: string = '10:00';

  email: string | null;

  purchaseButtonTitle = 'Continue';
  topImageClassName: string;

  title: string;
  topTitle: string;

  private isActive: boolean = true; //to kill the subscriptions

  constructor(
    private authService: AuthService,
    private flowService: OnboardingFlowService, 
    private pixel: EventsService) {}

  ngOnInit(): void {
    this.title = this.flowService.getPaywallTitle();
    this.topTitle = this.flowService.getTopTitle();
    this.topImageClassName = this.flowService.getPaywallMainImageClassName();
    
    this.authService.user$.pipe(
      take(1),
      tap(user => this.email = user?.email || null),
    ).subscribe();    
  }

  ngAfterViewInit(): void {
    this.startTimer();

    this.pixel.track('checkout_page_opened');
    track('web_checkout_view');
  }

  ngOnDestroy(): void {
    this.isActive = false;
  }

  payButtonClicked(): void {
    this.checkoutComponent.purchaseButtonClicked();
  }

  handlePurchaseButtonTitle(title: string) {
    this.purchaseButtonTitle = title;
  }

  private startTimer(): void {
    const secs = 10 * 60;

    interval(1000)
      .pipe(
        filter(passedSecs => passedSecs > 0),
        tap((passedSecs) => {
          const leftSecs = 60 - (passedSecs % 60);
          const leftSecsLabel = leftSecs > 9 ? `${leftSecs}` : `0${leftSecs}`;
          const minsLeft = Math.floor((secs - passedSecs) / 60);

          this.timer = `${minsLeft}:${leftSecsLabel}`;
        }),
        takeWhile(() => this.isActive),
      )
      .subscribe();
  }
}
