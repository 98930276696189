import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EMPTY, catchError, distinctUntilChanged, finalize, take, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';

//TODO: here we should add login through social networks 

@Component({
  selector: 'app-onboarding-sign-in-with-email',
  templateUrl: './sign-in-with-email.component.html',
  styleUrls: ['./sign-in-with-email.component.scss']
})
export class SignInWithEmailComponent {
  signIn = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    password: new FormControl('', [Validators.required]),
  });
  
  hidePassword = true;
  signInError = ''

  ngOnInit(): void {
    this.signIn.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => this.signInError = ''),
    )
    .subscribe();
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    private loadingService: LoadingService,
  ) {}

  continueButtonClicked(): void {
    const { email, password } = this.signIn.value;

    if (!email || !password || this.signIn.get('email')?.invalid) {
      return;
    }

    this.loadingService.start();

    this.authService.loginWithEmail(email, password)
      .pipe(
        take(1),
        tap(result => {
          if (result) {
            this.router.navigate(['onboarding', 'checkout']);
            return;
          }

          throw 'Something went wrong';
        }),
        catchError(error => {
          if(error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
            this.signInError = 'Invalid email or password'
          } else {
            this.signInError = 'Something went wrong. Please try again later.'
          }
          return EMPTY;
        }),
        finalize(() => this.loadingService.finish())
      )
    .subscribe();
  }
}
