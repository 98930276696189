<div class="quiz-container">
  <div class="progress-bar">
    <div class="progress-thumb" [style.width.%]="100 * (currentIndex + 1) / stepsCount"></div>
  </div>
    <div class="quiz-step" 
    [class.bottom-panel]="!data.options || data.isChips || data.isVoting || data.isMultiple"> 
      <div class="icon" *ngIf="data.topIcon">{{ data.topIcon }}</div>
      <h3 [innerHTML]="data.title"></h3>
      <div class="subtitle" *ngIf="data.subTitle && !(data.isVoting || data.lottieFilePath || data.imageClassName)">{{ data.subTitle }}</div>
      <div *ngIf="data.options || data.headline || data.lottieFilePath || data.imageClassName || data.audioTracks || data.hasSocialProof || data.userReview" class="content-container d-flex flex-column mt-20">

        <ng-container *ngIf="data.options && !data.isChips">
          <mat-selection-list *ngIf="data.isMultiple === false" [(ngModel)]="selectedOptions" [multiple]="false" [disableRipple]="true">
            <mat-list-option *ngFor="let option of data.options" [value]="option.title" (click)="optionClicked()">
              <div class="option-icon" *ngIf="option.emoji">{{ option.emoji }}</div>
              <div>{{ option.title }}</div>
            </mat-list-option>
          </mat-selection-list>
          <mat-selection-list *ngIf="data.isMultiple === true" [(ngModel)]="selectedOptions" [multiple]="true" [disableRipple]="true">
            <mat-list-option *ngFor="let option of data.options" [value]="option.title">
              <div class="option-icon" *ngIf="option.emoji">{{ option.emoji }}</div>
              <div>{{ option.title }}</div>
            </mat-list-option>
          </mat-selection-list>
        </ng-container>
 
        <mat-chip-listbox *ngIf="data.options && data.isChips" [(ngModel)]="selectedOptions" [multiple]="true" aria-label="Options selection">
          <mat-chip-option *ngFor="let option of data.options">
            {{ option.title }}
          </mat-chip-option>
        </mat-chip-listbox>

        <ng-container *ngIf="data.audioTracks">
          <app-audio-track 
            *ngFor="let track of data.audioTracks" 
            #audioTrackRef
            [data]="track" (onPlayed)="onPlay(audioTrackRef, track)">
          </app-audio-track>
        </ng-container>

        <ng-lottie
          *ngIf="data.lottieFilePath"
          [options]="{ path : data.lottieFilePath, loop: false }"
          containerClass="animation">
        </ng-lottie>

        <div *ngIf="data.imageClassName" class="image" [class]="data.imageClassName"></div>
        <div *ngIf="data.subTitle && (data.isVoting || data.lottieFilePath || data.imageClassName)" class="subtitle bottom">{{ data.subTitle }}</div>

        <h1 *ngIf="data.headline" class="display-1 headline">{{data.headline}}</h1>
        
        <app-social-proof *ngIf="data.hasSocialProof"></app-social-proof>

        <app-user-review
            *ngIf="data.userReview"
            [name]="data.userReview.name"
            [avatarUrl]="data.userReview.avatarUrl"
            [message]="data.userReview.message"
        ></app-user-review>
      </div>
    </div>

    <div *ngIf="(!data.options || data.isChips || data.isMultiple) && !data.isVoting" class="quiz-footer gradient">
      <button class="primary-button" [disabled]="data.isRequired && !selectedOptions.length" (click)="buttonClicked()">Continue</button>
    </div>

    <div *ngIf="data.isVoting" class="quiz-footer">
      <button class="voting-button" (click)="voteClicked('no')">👎</button>
      <button class="voting-button" (click)="voteClicked('miss')">🤷‍♂️</button>
      <button class="voting-button" (click)="voteClicked('yes')">👍</button>
    </div>
</div>