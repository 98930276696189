import { UserReview } from "src/app/shared/user-review/user-review.component";
import { AudioTrack } from "../../shared/audio-track/audio-track.component";

export interface QuizOption {
    title: string;
    emoji?: string;
}

export interface Quiz {
    title: string;
    subTitle?: string;
    options?: QuizOption[];
    isChips?: boolean;
    imageClassName?: string;
    lottieFilePath?: string;
    headline?: string;
    topIcon?: string;
    audioTracks?: AudioTrack[];
    isVoting?: boolean;
    hasSocialProof?: boolean; //it should be either social proof or review
    userReview?: UserReview;
    isMultiple: boolean;
    isRequired: boolean;
}

export const highlightText = (text: string) => {
  return `<span class="main-color">${text}</span>`;
}

export const interests = [
  '🏛 History',
  '💼 Business Management',
  '🏥 Clinical Medicine',
  '🔬 Physics',
  '💔 Dark Romance',
  '🌍 Environment & Sustainability',
  '📖 Fan Fiction',
  '🛡 Fantasy',
  '📈 Finance & Investing',
  '🏋️ Fitness & Health',
  '📢 Marketing',
  '🧠 Mental Health',
  '📰 News & Current Events',
  '👪 Parenting & Family',
  '📈 Personal Growth & Motivation',
  '📜 Religion & Spirituality',
  '❤️ Romance',
  '🔍 Thriller & Mystery',
  '🏦 Wealth Building',
  '💆 Well-Being',
  '🔬 Science',
] as const;

// {[key: string]: AudioTrack} 
export const audioTracks = {
  history: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/history.mp3',
    title: 'History'
  },

  business: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/business_management.mp3',
    title: 'Business Management'
  },

  medicine: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/clinical_med.mp3',
    title: 'Clinical Medicine'
  },

  physics: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/physics.mp3',
    title: 'Physics'
  },

  darkRomance: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/dark_romance.mp3',
    title: 'Dark Romance'
  },

  environment: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/environment.mp3',
    title: 'Environment'
  },

  fanfiction: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/fanfiction.mp3',
    title: 'FanFiction'
  },

  fantasy: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/fantasy.mp3',
    title: 'Fantasy'
  },

  finance: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/finance.mp3',
    title: 'Finance'
  },

  fitness: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/fitness.mp3',
    title: 'Fitness'
  },

  marketing: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/marketing.mp3',
    title: 'Marketing'
  },

  mentalHealth: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/mental_health.mp3',
    title: 'Mental Health'
  },

  news: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/news.mp3',
    title: 'News'
  },

  parenting: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/parenting.mp3',
    title: 'Parenting'
  },

  personalGrowth: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/personal_growth_motivation.mp3',
    title: 'Personal Growth'
  },

  religion: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/religious.mp3',
    title: 'Bible'
  },

  romance: {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/x1/romance.mp3',
    title: 'Romance'
  },

  science: {
    imagePath: 'assets/img/person4.webp',
    audioPath: 'assets/sounds/x1/science.mp3',
    title: 'Science'
  },

  thrillerMystery: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/thriller_mystery.mp3',
    title: 'Thriller'
  },

  wealthBuilding: {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/x1/wealth_building.mp3',
    title: 'Wealth Building'
  },

  wellBeing: {
    imagePath: 'assets/img/person3.webp',
    audioPath: 'assets/sounds/x1/well_being.mp3',
    title: 'Well-Being'
  },
};

export const quizSteps = {
    gender: {
      title: `Let's customize your ${highlightText('listening experience')}.<br />It will not take longer than 2-3 mins`,
      subTitle: 'Select your gender',
      options: [
        {
          title: '👱‍♂️ Male'
        }, {
          title: '👩 Female'
        }, {
          title: '🧓 Other'
        },
      ],
      isMultiple: false,
      isRequired: true,
    },
    age: {
      title: 'What is your age?',
      options: [
        {
          title: '🧑‍🎓 < 21',
        }, {
          title: '👩‍💼 21-34',
        }, {
          title: '👨‍💻 35-44',
        }, {
          title: '👩‍🏫 45+',
        },
      ],
      isMultiple: false,
      isRequired: true,
    },
    amountInfo: {
      title: 'Join Over 1 Million Users Your Age Worldwide',
      subTitle: 'Discover how Peech enhances productivity and supports health-related goals for people just like you',
      imageClassName: 'users-map',
      hasSocialProof: true,
      isRequired: false,
      isMultiple: false,
    },
    occupation: {
      title: 'What\'s your occupation?',
      subTitle: 'Knowing your occupation helps us tailor the experience to your needs',
      options: [
        '🧑‍🎓 Student',
        '💻 Office / Freelancer',
        '🛠 Trades & Service Worker',
        '🎨 Creative',
        '🚀 Entrepreneur',
        '🎥 Content Creator',
        '🏠 Stay-at-Home',
        '🧓 Retired',
        '🧑‍🤝‍🧑 Other',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    goal: {
      title: 'Set Your Life-Changing Goal!',
      subTitle: `We’ll help you make it happen, step by step`,
      options: [
        'Get a Raise or Promotion 💼',
        'Overcome Health Challenges 🌈',
        'Enter Top University 🎓',
        'Graduate with High Grades 🎓',
        'Read More & Enjoy Books 📖🎧',
        'Stay Focused & Get Things Done 🎯',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    goalInfo: {
      title: 'Professionals / Studens that use Peech are more productive',
      subTitle: 'By using 3 times a week they report that they are less likely to experience frustration from loss of concentration or reading overload',
      topIcon: '🔅',
      isMultiple: false,
      isRequired: false,
    },
    time: {
      title: 'When do you usually read or listen to content?',
      options: [
        {
          emoji: '☕️',
          title: 'In the morning',
        }, {
          emoji: '🏃‍♂️',
          title: 'While working out',
        }, {
          emoji: '🚗',
          title: 'On my commute',
        }, {
          emoji: '🛋',
          title: 'While taking a break',
        },
      ],
      isMultiple: true,
      isRequired: true,
    },
    duration: {
      title: 'How much time do you spend reading or listening each day?',
      options: [
        {
          title: 'About 15 mins',
          //emoji: '🤙',
        }, {
          title: '15 - 30 mins',
          //emoji: '👌',
        }, {
          title: '30 - 60 mins',
          //emoji: '🤘',
        }, {
          title: 'More than 1 hour',
          //emoji: '💪',
        },
      ],
      isMultiple: false,
      isRequired: true,
    },
    timeStatsInfo: {
      title: 'That\'s 4x more than most people!',
      subTitle: 'You are in top 25% among your age.',
      lottieFilePath: 'assets/lottie/comparison.json',
      isMultiple: false,
      isRequired: false,
    },
    format: {
      title: 'What text do you want to turn into audio?',
      options: [
        '📚 Textbooks & Assignments',
        '🌐 Web Articles & Blogs',
        '📧 Emails & Newsletters',
        '📄 Documents & PDFs',
        '✍️ My Own Writing, Notes & Memos',
        '📖 eBooks',
        '📑 Printed Texts',
        '💬 Social Media (Facebook, X, Instagram)',
        '🤔 Other'
      ].map(title => ({ title })),
      isMultiple: true,
      isRequired: true,
    },
    formatInfo: {
      title: 'Great choice!',
      subTitle: 'We support uploading these types of texts',
      topIcon: '🏋️‍♀️',
      isRequired: false,
      isMultiple: false,
    },
    screenTime: {
      //TODO: think - maybe just voting with picture?
      title: `How satisfied are you with your ${highlightText('current screen time')} habits?`,
      options: [
        'Very satisfied',
        'Somewhat satisfied',
        'Neutral',
        'Somewhat dissatisfied',
        'Very dissatisfied',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    screenTimeSocialProof: {
      title: `You are not alone. There are ${highlightText('more than 3M users')} in Peech who improve their digital habits every single day`,
      topIcon: '👐',
      userReview: {
        message: 'How the perfect AI reader cut my screen time by 25% in a week',
        name: 'Robert Ashton',
        avatarUrl: 'assets/img/avatars/7.png',
        logoUrl: 'assets/onboarding/ai_writer.svg'
      } as UserReview,
      isRequired: false,
      isMultiple: false,
    },
    screenTimeQuestion: {
      title: `What is your daily average Screen Time on your phone`,
      subTitle: `This can be checked in Settings`, //TODO: fix it
      options: [
        '1-2 hours',
        '2-3 hours',
        '3-5 hours',
        '5-8 hours',
        '8-12 hours',
        '12+ hours',
      ].map(title => ({ title })),
      isMultiple: false,
      isRequired: true,
    },
    screenTimeResult: {
      title: `The bad news is that you will spend this much time staring at your phone:`,
      topIcon: '😵‍💫',
      headline: '100500 hours',
      isRequired: false,
      isMultiple: false,
    },
    screenTimeImprovement: {
      title: `The good news is that Peech can help you get back `,
      topIcon: '🎉',
      headline: '5+ years',
      isRequired: false,
      isMultiple: false,
    },
    resultInfo: {
      title: `${highlightText('87% of Peech users')} have made positive changes in their lives`,
      subTitle: 'You\'re already on your way to positive change, too! Let\'s see what you are interested in exactly.',
      topIcon: '🤓',
      isRequired: false,
      isMultiple: false,
    },
    interests: {
      title: 'What else are you interested in?',
      options: [
        ...interests,
        '🤔 Others',
      ].map(title => ({ title })),
      isChips: true,
      isMultiple: true,
      isRequired: true,
    },
    soundInfo: {
      title: 'Check out how it sounds in our app!',
      subTitle: 'The AI voices are amazing and easy to understand',
      audioTracks: [audioTracks.business, audioTracks.history],
      isRequired: false,
      isMultiple: false,
    },
    speedInfo: {
      title: `How about getting it ${highlightText('a bit faster?')}`,
      subTitle: 'Let\'s try this on x1.5 and x2 speed. Using speed contol is true productivity boost and one of favorite features among our users',
      audioTracks: [audioTracks.medicine, audioTracks.physics],
      isRequired: false,
      isMultiple: false,
    },
    speedStatsInfo: {
      title: `With Peech you can save ${highlightText('at least 2 hours')} on reading this week!`,
      topIcon: '⏱',
      userReview: {
        message: 'The app is impressive. easy to listening to, no robotic voice, easy to use. productive use of my driving time. would recommend.',
        name: 'Jerin Barnes',
        avatarUrl: 'assets/img/avatars/5.png',
      },
      isRequired: false,
      isMultiple: false,
    },
    progressInfo: {
      title: `Start reading ${highlightText('more in less time')}`,
      subTitle: 'Thanks for taking the quiz 👍',
      lottieFilePath: 'assets/lottie/progress_graph.json',
      isMultiple: false,
      isRequired: false,
    },
};

export type QuizStepKey = keyof typeof quizSteps;

