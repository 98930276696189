import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DatePipe } from '@angular/common';
import { HttpClientModule, ɵHTTP_ROOT_INTERCEPTOR_FNS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmMailComponent } from './components/authentication/confirm-mail/confirm-mail.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { LoginComponent } from './components/authentication/login/login.component';
import { LogoutComponent } from './components/authentication/logout/logout.component';
import { PeechLoginComponent } from './components/authentication/peech-login/peech-login.component';
import { RegisterComponent } from './components/authentication/register/register.component';
import { ResetPasswordComponent } from './components/authentication/reset-password/reset-password.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderComponent } from './components/common/header/header.component';
import { InternalErrorComponent } from './components/common/internal-error/internal-error.component';
import { NotFoundComponent } from './components/common/not-found/not-found.component';
import { EducationLandingComponent } from './components/landings/education/education-landing.component';
import { ExistingUserDiscountPaywallComponent } from './components/landings/existing-user-discount-paywall/existing-user-discount-paywall.component';
import { RootLandingComponent } from './components/landings/root-landing/root-landing.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaymentResultComponent } from './components/payment-result/payment-result.component';
import { ProfileComponent } from './components/profile/profile.component';
import { authInterceptor } from './interceptors/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { StoreButtonComponent } from './w2a/components/store-button/store-button.component';
import { StoreRedirectComponent } from './w2a/components/store-redirect/store-redirect.component';
import { OnboardingModule } from './onboarding/onboarding.module';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        HeaderComponent,
        NotFoundComponent,
        InternalErrorComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        LoginComponent,
        RegisterComponent,
        LogoutComponent,
        PeechLoginComponent,
        ConfirmMailComponent,
        RootLandingComponent,
        EducationLandingComponent,
        ExistingUserDiscountPaywallComponent,
        PaymentResultComponent,
        ProfileComponent,
        LoadingComponent,
        ModalComponent,
        StoreButtonComponent,
        StoreRedirectComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        MatMenuModule,
        MatCardModule,
        BrowserAnimationsModule,
        MatProgressBarModule,
        MatButtonModule,
        MatBottomSheetModule,
        MatButtonToggleModule,
        SharedModule,
        //MatCheckboxModule,
        //MatChipsModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSlideToggleModule,
        //MatSnackBarModule,
        //MatTabsModule,
        //MatToolbarModule,
        //MatTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CdkAccordionModule,
        OnboardingModule
    ],
    providers: [
        DatePipe,
        {
            provide: ɵHTTP_ROOT_INTERCEPTOR_FNS,
            useValue: authInterceptor,
            multi: true
        },
        provideLottieOptions({
            player: () => import('lottie-web'), //import('lottie-web/build/player/lottie_light'),
        }),
        provideCacheableAnimationLoader(),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
