import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

export type ProductCode = 
  'peech.web.week.premium.notrial' 
| 'peech.web.month.premium.trial-3'
| 'peech.web.month.premium.trial-3'
| 'peech.web.quarter.premium.notrial'
| 'peech.web.year.premium.notrial' 
| 'peech.web.year.premium.trial-3';

export enum BillingPeriod {
  Week = 'week',
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
};

export interface Product {
  id: number;
  code: ProductCode;
  billingPeriod: BillingPeriod;
  billingPeriodDays: number;

  trialDays: number;
  
  setupFeePriceUsd?: number;

  introductoryPriceUsd?: number;

  recurringPriceUsd: number;

  dailyPriceUsd: number;
  dailyIntroductoryPriceUsd?: number;

  environment: string; //keyof typeof subscriptionEnvironments;

  isDefault: boolean;
};

export interface SubscriptionState {
  isSubscribed: boolean;
  isActivePromo: boolean;
  expireDate: Date | null;
  type: string;
  quotas?: Record<string, number>;
}

export interface User {
  userId: number;
  email: string;
  firstName?: string;
  lastName?: string;
}

export interface UserDetails extends User {
  isEligibleForTrial: boolean;
  subscriptionState: SubscriptionState;
  webProduct?: Product;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {  
  private readonly baseUrl = environment.serviceUrl;

  constructor(private http: HttpClient) {}

  getProducts(placement: 'discount' | 'main') {
    const url = `${this.baseUrl}/products?placement=${placement}${!environment.production ? '&env=DEV' : ''}`;

    return this.http.get<{ products: Product[] }>(url).pipe(map(response => response.products))
  }

  createStripeSubscription(productId: number, email: string | null) {
    return this.http.post<{ clientSecret: string; isSetupIntent: boolean; stripeCustomerId: string; }>(`${this.baseUrl}/stripe/create-subscription`, {
      productId,
      email
    });
  }

  cancelStripeSubscription() {
    return this.http.post(`${this.baseUrl}/stripe/cancel-subscription`, null);
  }

  getUserDetails() {
    return this.http.get<UserDetails>(`${this.baseUrl}/users/details`);
  }

  identifyUser(id: string) {
    return this.http.get<User>(`${this.baseUrl}/users/identify?id=${id}`);
  }
}
