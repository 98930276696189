<div class="icon" *ngIf="option.emoji">{{ option.emoji }}</div>
<canvas
    *ngIf="option.rive"
    #animation
    class="animation" 
    width="240"
    [height]="240 / option.rive.ratio"
    >
</canvas>
<div>{{ option.title }}</div>
