<div
    class="pt-5 pb-50"
    [class.component-dark-theme]="themeService.isDark()"
>
    <div class="row align-items-center">
        <div class="col-lg-5 d-flex justify-content-center">
            <img class="top-image" src="assets/img/top2.png">
        </div>
        <div class="col-lg-7 mt-20 mb-20">
            <h1 class="display-3">Listen to Academic Papers on the Go</h1>
            <div class="lead">Save hours by listening to Papers anywhere, in your spare time</div>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-10">
            <app-audio-track [data]="trackHistory"></app-audio-track>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-10">
            <app-audio-track [data]="trackPhysics"></app-audio-track>
        </div>
    </div>
    <div class="row justify-content-center mt-20">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <button (click)="checkoutClicked()" mat-raised-button class="pulsing tagus w-100">{{purchaseButtonTitle}}</button>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">Used by <span class="main-color">40K+ Students & Researchers</span> at...</h1>
    </div>
    <div class="row mt-30">
        <div class="universities d-flex align-items-center">
            <img class="univ-image" [src]="slide" *ngFor="let slide of slides">
            <img class="univ-image" [src]="slide" *ngFor="let slide of slides">
        </div>
        <div class="col-12 mt-20">
            <p class="lead">... and many more!</p>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">The world’s first mobile app <span class="main-color">built for listening to papers</span></h1>
    </div>
    <div class="row align-items-center mt-20">
        <div class="col-lg-6 col-sm-12 d-flex justify-content-center">
            <img class="top-image" src="assets/img/resources.png">
        </div>
        <div class="col-lg-6 col-sm-12 mt-20">
            <div class="lead">We've carefully hand-crafted the app for students & researchers, including:</div>
            <div class="mt-15 ps-20 text-list">
                <div>Lifelike voices, complete with emotion and intonation</div>
                <div>Easily pronounces technical words in any field</div>
                <div>Numerous formats supported: PDFs, Docs, E-books, Web Articles and even Photos</div>
                <div>More than 50 languages supported</div>
            </div>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row align-items-center">
        <div class="col-lg-5 light-green-color border-radius p-25 d-flex flex-column">
            <h5 class="display-6">Just Hit <span class="ri-play-fill main-color"></span> to Try</h5>
            <app-audio-track [data]="trackMedicine"></app-audio-track>
            <app-audio-track [data]="trackBusiness"></app-audio-track>
            <app-audio-track [data]="trackHistory"></app-audio-track>
            <app-audio-track [data]="trackPhysics"></app-audio-track>
        </div>
        <div class="col-lg-7 mt-20 mb-20">
            <h1 class="display-5">"I can't believe it - he <span class="main-color">sounds like a real person</span>"</h1>
            <div class="lead">We use the latest AI breakthroughs to create human-like voices. They’re easy to listen to and understand, just like an audiobook!</div>
            <div class="mt-15 ps-20 text-list">
                <div class="mb-15">Lifelike voices, complete with emotion and intonation</div>
                <div class="mb-15">Easily pronounces technical words in any field</div>
                <div class="mb-15">Remove excess text, like references citations, from the audio</div>
            </div>
        </div>
    </div>

    <div class="row align-items-stretch mt-10">
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-10">
            <app-user-review
            name="Jayati S."
            avatarUrl="assets/img/avatars/8.png"
            message="Having an app that converts all my reading into voice is just so convenient and amazing"
        ></app-user-review>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-10">
            <app-user-review
            name="Anna V."
            avatarUrl="assets/img/avatars/1.png"
            message="I'm really picky about Al voices, but this one was amazing! Even at 1.5x speed, it was engaging to listen to. It's a game changer to be able to listen while walking!"
        ></app-user-review>
        </div>
    </div>
    <div class="row justify-content-center mt-20">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <button (click)="checkoutClicked()" mat-raised-button class="pulsing tagus w-100">{{purchaseButtonTitle}}</button>
        </div>
    </div>

    <!-- <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row mt-20 usecase-images">
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-10">
            <div class="image border-radius position-relative">
                <div class="image-label bg-main-color d-flex flex-column border-radius position-absolute p-10">
                    <span>Listen on the </span><strong>Bus</strong>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-10">
            <div class="image border-radius position-relative">
                <div class="image-label bg-main-color d-flex flex-column border-radius position-absolute p-10">
                    <span>Listen while</span><strong>Cooking</strong>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-10">
            <div class="image border-radius position-relative">
                <div class="image-label bg-main-color d-flex flex-column border-radius position-absolute p-10">
                    <span>Listen while</span><strong>Driving</strong>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-10">
            <div class="image border-radius position-relative">
                <div class="image-label bg-main-color d-flex flex-column border-radius position-absolute p-10">
                    <span>Listen while</span><strong>Excercising</strong>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center mt-20">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <button (click)="checkoutClicked()" mat-raised-button class="pulsing tagus w-100">{{purchaseButtonTitle}}</button>
        </div>
    </div> -->

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">Upload from <span class="main-color">desktop or mobile</span> in seconds</h1>
    </div>
    <div class="row align-items-center mt-20">
        <div class="col-lg-6 col-sm-12 d-flex justify-content-center">
            <img class="top-image" src="assets/img/extension.png">
        </div>
        <div class="col-lg-6 col-sm-12">
            <div class="lead">Here's how to upload a paper from any device:</div>
            <div class="mt-15 ps-20 text-list">
                <div><strong>Mobile:</strong> Click the "share" button on the bottom of your mobile browser</div>
                <div><strong>Desktop:</strong> Click the Chrome extension on a web page</div>
                <div><strong>Upload:</strong> You can also upload any file directly, in our iOS app</div>
                <div><strong>Picture:</strong> In case you need to listen to printed text - use Camera in our iOS app</div>
            </div>
            <div class="lead">The audio will be instantly available on your smartphone to listen to!</div>
        </div>
    </div>
    <div class="row justify-content-center mt-20">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <button (click)="checkoutClicked()" mat-raised-button class="pulsing tagus w-100">{{purchaseButtonTitle}}</button>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6"><span class="main-color">Save time</span> by choosing which sections to listen to</h1>
    </div>
    <div class="row align-items-center mt-20">
        <div class="col-lg-6 col-sm-12 d-flex justify-content-center">
            <img class="top-image" src="assets/img/top_library.png">
        </div>
        <div class="col-lg-6 col-sm-12 mt-20 mb-20">
            <div class="lead">You can start listening or, obvisouly, reading from any place in the text</div>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6"><span class="main-color">Take notes</span> on key ideas with one click</h1>
    </div>
    <div class="row align-items-center mt-20">
        <div class="col-lg-6 col-sm-12 d-flex justify-content-center">
            <img class="top-image" src="assets/img/top_content.png">
        </div>
        <div class="col-lg-6 col-sm-12">
            <p class="lead">Take notes while on the go, with just one click!</p>
            <p class="lead">Click the +note button any time you hear a key idea, and the app instantly adds the last few sentences to a notepad.</p>
            <p class="lead">Export your notes later to <strong>make writing papers a breeze.</strong></p>
        </div>
    </div>
    <div class="row justify-content-center mt-20">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <button (click)="checkoutClicked()" mat-raised-button class="pulsing tagus w-100">{{purchaseButtonTitle}}</button>
        </div>
    </div>
    
    <!-- <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">Listen to <span class="main-color">Unlimited</span> Research Papers</h1>
    </div>
    <div class="row align-items-center mt-20">
        <div class="col-lg-6 col-sm-12 d-flex justify-content-center">
            <img class="top-image border-radius" src="assets/img/pictures/pile.png">
        </div>
        <div class="col-lg-6 col-sm-12 mt-20">
            <p class="lead">Sometimes, you just have to <strong>get through 300 pages as quickly</strong> as possible.</p>
            <p class="lead">We've got you covered.</p>
            <p class="lead">Whether you're working on a thesis or just want to stay on top of your studies, you can access UNLIMITED audio any time.</p>
            <p class="lead">Say goodbye to limits and hello to limitless learning.</p>
        </div>
    </div> -->

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">What people think <span class="main-color">about us</span></h1>
    </div>
    <div class="row reviews align-items-stretch justify-content-center mt-20">
        <app-user-review
            name="Jayati S"
            message="Having an app that converts all my reading into voice is just so convenient and amazing"
            avatarUrl="assets/img/avatars/1.png"
        ></app-user-review>
        <app-user-review
            name="Lauren Fratamico"
            message="I always have stacks of papers I want to get through, and it's been so nice to finally find an app that allows me to listen to them on the go with a naturalistic voice. Would recommend!"
            avatarUrl="assets/img/avatars/2.png"
        ></app-user-review>
        <app-user-review
            name="Mary Ballard-Johansson"
            message="What a great app! The reading voice is pleasant and understandable. Even on the harder words. Having the text available to read along helps also. Naturally, as with any new app, there are bugs. But support is readily available. Highly recommend this for anyone doing research or just keeping up with the latest articles."
            avatarUrl="assets/img/person3.webp"
        ></app-user-review>
        <app-user-review
            name="AlexP1799"
            message="This app is a MUST for anyone in academics. I’ve only had it for a couple of weeks and I’m already sold. I’m in grad school at the moment and almost all of our reading are academic articles. It takes me ages to get through them every week and by the end it feels like my eyes are going to fall out of my head."
            avatarUrl="assets/img/avatars/3.png"
        ></app-user-review>
        <app-user-review
            name="Watson Chiwambo"
            message="Amazing app to help you study especially if you don't have much time due to work, family and other things."
            avatarUrl="assets/img/avatars/4.png"
        ></app-user-review>
        <app-user-review
            name="Jenny0987"
            message="This app is such a great concept! Makes it easy to do everything I want to do all in one. Great way to multi task. This app would be so useful with school. It can read to you while you’re doing work!"
            avatarUrl="assets/img/avatars/7.png"
        ></app-user-review>
        <app-user-review
            name="Erin Barnes"
            message="The app is impressive. easy to listening to, no robotic voice, easy to use. productive use of my driving time. would recommend."
            avatarUrl="assets/img/avatars/5.png"
        ></app-user-review>
        <app-user-review
            name="Alisson X"
            message="It is the perfect application to study without having to stop doing your things, I put my headphones on and it saves me a lot of time. Totally recommended!"
            avatarUrl="assets/img/avatars/6.png"
        ></app-user-review>
    </div>
    <div class="row justify-content-center mt-20">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <button (click)="checkoutClicked()" mat-raised-button class="pulsing tagus w-100">{{purchaseButtonTitle}}</button>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">This transformational tool is <span class="main-color">more affordable</span> than ...</h1>
    </div>
    <div class="row mt-20 cost-images">
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-15">
            <div class="lead w-100">... A new <strong class="main-color">Text Book</strong></div>
            <div class="image border-radius position-relative mt-15">
                <div class="image-label position-absolute">
                    <strong>Cost: $150</strong>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-15">
            <div class="lead w-100">... A <strong class="main-color">used</strong> Text Book</div>
            <div class="image border-radius position-relative mt-15">
                <div class="image-label position-absolute">
                    <strong>Cost: $60</strong>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-15">
            <div class="lead w-100">... A cell <strong class="main-color">Phone Plan</strong></div>
            <div class="image border-radius position-relative mt-15">
                <div class="image-label position-absolute">
                    <strong>Cost: $40 / month</strong>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-md-6 col-sm-12 mt-15">
            <div class="lead w-100">... A <strong class="main-color">Netflix</strong> Subscription</div>
            <div class="image border-radius position-relative mt-15">
                <div class="image-label position-absolute">
                    <strong>Cost: $15 / month</strong>
                </div>
            </div>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
        <div class="row checkout-container" #checkout>
            <div
        class="checkout d-flex flex-column justify-content-center align-items-center"
        [class.component-dark-theme]="themeService.isDark()"
        #checkoutElement
        >
            <div class="row justify-content-center">
                <h1 class="display-6 text-center">Listen to Papers & Articles Anywhere</h1>
                <!-- <h1 class="display-5 main-color text-center">Try the app for 2 weeks just for $0.99</h1> -->
                <h4 class="text-center">
                    Get <span class="main-color">unlimited listening</span> with a <span class="red-color">limited time 40% discount</span>. Cancel anytime.
                </h4>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mt-30 pt-10">
                    <app-checkout [placement]="'discount'"></app-checkout>
                </div>
                <div class="col-12 col-md-6 mt-30">
                    <div class="listbox-container">
                        <!-- <p class="">
                            Try unlimited listening. After the first 2 weeks, it’s $12/mo or $99/yr. Cancel anytime with 1 click.                
                        </p> -->
                        <ul class="listbox">
                            <li class="d-flex align-items-center mb-20">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Listen to unlimited research papers</span>
                            </li>
                            <li class="d-flex align-items-center mb-20">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Take notes while you listen (with 1 click)</span>
                            </li>
                            <li class="d-flex align-items-center mb-20">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Upload from mobile or desktop</span>
                            </li>
                            <li class="d-flex align-items-center mb-20">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Pick which sections to listen to</span>
                            </li>
                            <li class="d-flex align-items-center">
                                <i class="flaticon-check-mark-1 main-color"></i>
                                <span>Bonus: Listen to web pages & emails</span>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-25">
                        <app-user-review
                            name="Ricky Y."
                            logoUrl="assets/img/universities/1.png"
                            message="I’ve tried other text-to-audio apps, but they were terrible. Peech actually made it stick for me. I’m in love with the app - thank you!!"
                        ></app-user-review>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <mat-divider class="mt-25 mb-25"></mat-divider>
    <div class="row">
        <h1 class="display-6">Frequently Asked Questions</h1>
    </div>
    <div class="col-lg-6 col-md-12 mt-25">
        <mat-accordion class="tagus-basic-expansion mb-20">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        How do I cancel my account?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>It's easy! Just log into your account here, go to "Settings", and click the Cancel button.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        What happens if I cancel during the 2 week trial?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>You will be charged just $0.99, no full price!</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Can it pronounce difficult words, like "Mesothelioma" or "Diphenhydramine"?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Yep. If our audio ever mispronounces a word, just let us know and we'll fix it.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        I don't want to listen to all the references and citations. Can your app skip those?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Yes, we skip those, so you can focus on the core material.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        I only want to listen to useful content of the web article. How do I do that?
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>We pull only useful text from any web page or document!</p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="row justify-content-center mt-20">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <button (click)="checkoutClicked()" mat-raised-button class="pulsing tagus w-100">{{purchaseButtonTitle}}</button>
        </div>
    </div>
    <button mat-fab *ngIf="isScrollToTopButtonShown" (click)="scrollToTop()" class="scroll-to-top">
        <mat-icon>arrow_upward</mat-icon>
    </button>
</div>