import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AudioTrackComponent } from './audio-track/audio-track.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { SocialProofComponent } from './social-proof/social-proof.component';
import { UserReviewComponent } from './user-review/user-review.component';
import { PaymentComponent } from './payment/payment.component';

@NgModule({
  declarations: [AudioTrackComponent, CheckoutComponent, SocialProofComponent, UserReviewComponent, PaymentComponent],
  imports: [
    CommonModule,
        MatButtonModule,
        MatButtonToggleModule,
        //MatCheckboxModule,
        //MatChipsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        FormsModule,
        ReactiveFormsModule,
  ],
  exports: [AudioTrackComponent, CheckoutComponent, SocialProofComponent, UserReviewComponent]
})
export class SharedModule {}
