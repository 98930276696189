import { track } from '@amplitude/analytics-browser';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EMPTY, catchError, distinctUntilChanged, finalize, take, tap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ModalComponent, ModalData } from 'src/app/shared/modal/modal.component';
import { OnboardingFlowService } from '../../services/onboarding-flow.service';
import { highlightText } from '../../services/quiz';

@Component({
  selector: 'app-onboarding-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, AfterViewInit {
  @ViewChild('emailInput') emailInput!: ElementRef;

  email = new FormControl('', [Validators.required, Validators.pattern(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i)]);
  
  isExistingEmail = false;

  //title = `Achieve your goal ${highlightText('with Peech')}`;
  title = `Enter your email ${highlightText('to get the results in a few steps')}`;
  //subtitle = 'Enter your email to complete your personal account setup';

  //isQuickfixToggled: boolean = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private flowService: OnboardingFlowService,
    private loadingService: LoadingService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.email.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => this.isExistingEmail = false),
    )
    .subscribe();

//    const name = this.flowService.getName();

    // if (name) {
    //   this.subtitle = `${name}, enter your email to complete your personal account setup`;
    // }

    // this.email.statusChanges.pipe(
    //   distinctUntilChanged(),
    //   tap(() => this.isQuickfixToggled = !this.isQuickfixToggled),
    // )
    // .subscribe();
  }

  ngAfterViewInit(): void {
    track('web_login_email_view');
  }

  navigateToSignIn(): void {
    this.router.navigate(['onboarding', 'email-sign-in']);
  }

  /*
  appleClicked(): void {
    this.loadingService.start();

    this.authService.login('apple')
      .pipe(
        take(1),
        finalize(() => this.loadingService.finish()),
        tap(result => {
          if (result) {
            this.router.navigate(['onboarding', 'checkout'], { queryParamsHandling: 'merge' });
            return;
          }

          throw 'Something went wrong';
        }),
        catchError((error) => { 
          alert('Sorry. Failed to Sign You In. Please try again.');
          track('web_unexpected_error', { error });
          
          return EMPTY;
        }))
      .subscribe();
  }
  */

  enterPressed(): void {
    if (!this.email.value || this.email.invalid) {
      return;
    }

    this.emailInput.nativeElement.blur();

    this.loginEmail();
  }

  continueButtonClicked(): void {
    if (this.email.invalid) {
      return;
    }

    this.loginEmail();
  }

  private loginEmail(): void {
    this.loadingService.start();

    this.authService.signUpWithEmail(this.email.value!)
      .pipe(
        take(1),
        tap(result => {
          if (result) {
            this.flowService.handleFinishedLogin(this.email.value!);
            return;
          }

          throw 'Something went wrong';
        }),
        finalize(() => this.loadingService.finish()),
        catchError(error => {
          if(error.code === 'auth/email-already-in-use') {
            this.isExistingEmail = true
          } else {
            this.showError();
          }

          return EMPTY;
        }),
      )
      .subscribe();
  }

  private showError() {
    this.dialog.open<ModalComponent, ModalData>(ModalComponent, {
      data: {
        title: 'Sorry',
        message: 'Failed to Sign You In. Please try again.',
      }
    })
      .afterClosed()
      .subscribe();
  }
}
