<div class="audio-player d-flex align-items-center justify-content-space-between p-10" [class.playing]="isPlaying">
    <img src="{{data.imagePath}}" alt="speaker" class="speaker-image">
    <div class="middle">
      <span class="track-title">{{data.title}}</span>
      <div class="waveform" #waveform></div>
    </div>
    <button class="play-button p-0" (click)="playPause()">
      <i class="{{ isPlaying ? 'ri-pause-line' : 'ri-play-fill' }}"></i>
    </button>
</div>
  