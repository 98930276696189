import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var fbq: any;

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor() {
    if (!environment.production) {
      (window as any).fbq = ((_: string, b: string) => console.log(`Simulating fb pixel track: ${b}`));
    }
  }

  track(event: 'first_open' | 'subscribe' | 'checkout_page_opened' | 'purchase_tapped' | 'page_view' | 'signed_in'): void {
    switch (event) {
      case 'first_open':
        fbq('track', 'ViewContent');
        break;
      case 'subscribe':
        fbq('track', 'Purchase');
        break;
      case 'checkout_page_opened':
        fbq('track', 'Lead');
        break;
      case 'purchase_tapped':
        fbq('track', 'InitiateCheckout');
        break;
      case 'page_view':
        fbq('track', 'PageView');
        break;
      case 'signed_in':
        fbq('track', 'CompleteRegistration');
        break;
      default:
        break;
    }
  }
}
