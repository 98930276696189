import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize, map, take, tap } from 'rxjs';
import { ApiService, BillingPeriod, Product } from 'src/app/services/api.service';
import { PaymentComponent, PaymentData } from '../payment/payment.component';
import { LoadingService } from '../../services/loading.service';
import { EventsService } from 'src/app/services/events.service';
import { track } from '@amplitude/analytics-browser';

export interface ProductView extends Product {
  title: string;
  fullPriceLabel: string;
  priceLabel: string;
  fullDailyPriceLabel: string;
  dailyPriceLabel?: string;
  discountAmountLabel?: string;
  isBestDeal: boolean;
  //infoLabel: string;
}

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent implements OnInit {
  @Input() placement: 'main' | 'discount'; //TODO: introduce enum & proper options
  @Input() email: string | null = null;
  @Input() discount: number = 0.4;

  @Output() purchaseButtonTitle = new EventEmitter<string>();

  currentPurchaseButtonTitle: string = 'Continue';
  
  products: ProductView[] = [];

  isLoading: boolean = true;

  selectedProduct: Product;

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private pixel: EventsService,
    private loadingService: LoadingService,
  ) {}

  ngOnInit(): void {
    this.purchaseButtonTitle.emit(this.currentPurchaseButtonTitle);

    this.api.getProducts(this.placement)
      .pipe(
        take(1),
        map(products => products.map(product => this.mapProductView(product))),
        tap(products => this.products = products),
        map(() => this.getDefaultSelectedProduct()),
        tap(product => this.setSelectedProduct(product)),
        finalize(() => this.isLoading = false),
      )
      .subscribe();
  }

  purchaseButtonClicked() {
    this.pixel.track('purchase_tapped');
    track('web_purchase_tapped');
    
    this.loadingService.start();

    this.api.createStripeSubscription(this.selectedProduct.id, this.email)
      .pipe(
        tap(() => this.loadingService.finish()),
        tap(({ clientSecret, isSetupIntent }) => this.dialog.open(PaymentComponent, {
          data: {
            product: this.selectedProduct,
            email: this.email,
            buttonTitle: this.currentPurchaseButtonTitle,
            clientSecret,
            isSetupIntent,
          } as PaymentData,
          width: '90vw',
          maxWidth: '500px',
          maxHeight: '80vh'
        }).afterClosed()
      ))
      .subscribe();
      //TODO: if it is closed - show the one with more discount
  }

  productClicked(product: ProductView) {
    this.setSelectedProduct(product);
  }

  private setSelectedProduct(product: ProductView) {
    this.selectedProduct = product;
    this.setPurchaseButtonTitle();
  }

  private setPurchaseButtonTitle() {
    //TODO: add the logic here to check if it is free trial, of there is a discount etc
    if (this.selectedProduct.trialDays > 0) {
      this.currentPurchaseButtonTitle = `Try ${this.selectedProduct.trialDays}-Day Trial`;
    } else {
      this.currentPurchaseButtonTitle = 'Claim My Discount';
    }

    this.purchaseButtonTitle.emit(this.currentPurchaseButtonTitle);
  }

  private getDefaultSelectedProduct() {
    let bestProduct;

    if (this.products.length === 1) {
      bestProduct = this.products[0];
    } else {
      const defaultProduct = this.products.find(product => product.isDefault);

      if (defaultProduct) {
        bestProduct = defaultProduct;
      } else {
        bestProduct = this.products.sort((p1, p2) => p1.billingPeriodDays - p2.billingPeriodDays)[0];
      }
    }

    bestProduct.isBestDeal = true; //to put a label on it TODO: this is not a right place

    return bestProduct;
  }

  private mapProductView(product: Product): ProductView {
    const discountAmount = product.introductoryPriceUsd ? (product.recurringPriceUsd - product.introductoryPriceUsd) / product.recurringPriceUsd : 0;
    const discountAmountLabel = `${Math.round(100 * discountAmount)}%`;

    return {
      ...product,
      isBestDeal: false,
      title: this.getProductTitle(product),
      priceLabel: `$${product.introductoryPriceUsd || product.setupFeePriceUsd || product.recurringPriceUsd}`,
      fullPriceLabel: `$${product.recurringPriceUsd}`,
      fullDailyPriceLabel: `$${(product.dailyPriceUsd / (1 - this.discount)).toFixed(2)}`.replace('.', ','),
      dailyPriceLabel: `$${product.dailyIntroductoryPriceUsd ? product.dailyIntroductoryPriceUsd : product.dailyPriceUsd }`.replace('.', ','),
      discountAmountLabel,
    };
  }

  private getProductTitle(product: Product): string {
    // const titles: { [key in ProductCode]: string; } = {
    //   'peech.web.year.premium.notrial': 'Yearly Plan',
    //   'peech.web.week.premium.notrial': 'Weekly Premium',
    //   'peech.web.quarter.premium.notrial': '3-Months Plan',
    //   'peech.web.month.premium.trial-3': 'Monthly Plan',
    //   'peech.web.year.premium.trial-3': 'Yearly Plan',
    // };

    //const title = titles[product.code] || 'Peech Premium';

    switch (product.billingPeriod) {
      case BillingPeriod.Month:
        return '1 Month';
      case BillingPeriod.Quarter:
        return '3 Months';
      case BillingPeriod.Year:
        return '12 Months';
      case BillingPeriod.Week:
        return '1 Week';
      default:
        return 'Premium Plan';
    }

    // if (product.trialDays > 0) {
    //   return `${title}, ${product.trialDays} Days Free Trial`;
    // }
  }

  // private getProductInfoLabel(product: Product): string {
  //   const labels: { [key in ProductCode]: string; } = {
  //     'peech.web.year.premium.notrial': 'for 1 year',
  //     'peech.web.week.premium.notrial': 'You Pay Now',
  //     'peech.web.quarter.premium.notrial': 'for 3 months',
  //     'peech.web.month.premium.trial-3': `$ ${product.recurringPriceUsd} after ${product.trialDays} days for free`,
  //     'peech.web.year.premium.trial-3': `$ ${product.recurringPriceUsd} after ${product.trialDays} days for free`
  //   };

  //   return labels[product.code] || 'some time';
  // }
}
