import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EventsService } from 'src/app/services/events.service';
import { CustomizerSettingsService } from '../../../services/customizer-settings.service';
import { AudioTrack } from '../../../shared/audio-track/audio-track.component';

@Component({
  selector: 'app-education-landing',
  templateUrl: './education-landing.component.html',
  styleUrl: './education-landing.component.scss'
})
export class EducationLandingComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('checkoutElement') checkoutElement: ElementRef;
  @ViewChild('checkout') checkoutComponent: ElementRef;
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const checkoutPosition = this.checkoutComponent.nativeElement.getBoundingClientRect().top;
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

    this.isScrollToTopButtonShown = scrollPosition > checkoutPosition;
  }

  slides = [
    'assets/img/universities/1.png',
    'assets/img/universities/2.png',
    'assets/img/universities/4.png',
    'assets/img/universities/5.png',
    'assets/img/universities/3.png',
    'assets/img/universities/6.png',
    'assets/img/universities/7.png',
  ];

  isScrollToTopButtonShown = false;

  purchaseButtonTitle = 'Redeem My Discount';

  trackHistory: AudioTrack = {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/history.mp3',
    title: 'History'
  };

  trackBusiness: AudioTrack = {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/business_management.mp3',
    title: 'Business Management'
  };

  trackMedicine: AudioTrack = {
    imagePath: 'assets/img/person1.png',
    audioPath: 'assets/sounds/clinical_med.mp3',
    title: 'Clinical Medicine'
  };

  trackPhysics: AudioTrack = {
    imagePath: 'assets/img/person2.png',
    audioPath: 'assets/sounds/physics.mp3',
    title: 'Physics'
  };

  private observer: IntersectionObserver;
  private isEventTriggered = false;

  constructor(
    public themeService: CustomizerSettingsService, //TODO: make sure we support dark theme
    private eventsService: EventsService,
  ) {}

  ngOnInit(): void {
    //this.eventsService.track('page_view');
  }

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.triggerVisibilityEvent();
        }
      });
    }, {threshold: 0.1}); // Adjust threshold as needed

    this.observer.observe(this.checkoutElement.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  checkoutClicked() {
    this.checkoutComponent.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  private triggerVisibilityEvent() {
    if (this.isEventTriggered) {
      return;
    }

    this.eventsService.track('checkout_page_opened');
    //track('web_payment_checkout_view');

    this.isEventTriggered = true;
  }
}
